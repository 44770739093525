import React, { useState } from 'react';
import {
    Box, Typography,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useContextData } from '../../core/context';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import './Portfolio.css'
import setringImg from '../../assets/images/setting.png'
import { Spinner } from 'react-bootstrap';
import TransferModalUSDT from './TransferModalUSDT';

const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "18px",
    fontWeight: "500",
    fontStyle: "normal",
    padding: "5px",
    paddingBottom: "16px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
}));
const StyledTableCellFirst = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    color: "white",
    minWidth: "auto !important",
    textAlign: "center",
}));
const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "32px",
    fontWeight: "600",
    padding: "5px",
    fontStyle: "normal",
    lineHeight: "38px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
}));

const WalletListFutures = (props) => {
    const {
        walletData = [],
        balShow = false,
        fundMoveUSDT, fundUSDTLoading, loadingUsdt,
        coindcxDecimal = {}, balShowHideCall, loading,

    } = props;
    const { myProfile, futuresBalance } = useContextData();
    const [foc, setFoc] = useState(false);
    return (
        <div className='mb-5 pt-2 mt-1'>

            <Box
                sx={
                    {
                        // bgcolor: '#282525',
                    }
                }
            >
                <Box
                    sx={{
                        bgcolor: '#3d3d3d',
                        display: { md: 'flex' },
                        p: 2,
                        pl: 3,
                        pr: 3,
                        borderRadius: 5,

                        justifyContent: 'space-between',
                    }}

                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                textAlign: { xs: 'center', md: 'left' },
                                fontSize: { xs: '0.85rem', lg: '0.9rem' },
                            }}
                        >
                            Estimated Balance {balShow ? <AiFillEye
                                onClick={() => balShowHideCall()}
                                sx={{ color: "white", marginTop: "-5px", marginLeft: "5px", cursor: "pointer" }} /> : <AiFillEyeInvisible
                                onClick={() => balShowHideCall()}
                                sx={{ color: "white", marginTop: "-5px", marginLeft: "5px", cursor: "pointer" }} />}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { xs: '1rem', lg: '1.2rem' },
                                textAlign: { xs: 'center', md: 'left' },
                                fontWeight: 'bold',
                                mt: 1.5,
                            }}
                        >
                            {/* {((futuresBalance?.availableBalance || 0) * (futuresBalance?.usdtPrice || 0)).toFixed(2)} */}
                            {balShow ? <>&#8377; {loading ? 'Loading...' : ((futuresBalance?.availableBalance || 0) * (futuresBalance?.usdtPrice || 0)).toFixed(2)}</> : <>***Balance hidden***</>}
                        </Typography>
                    </Box>
                    {myProfile &&
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 3,
                                alignItems: 'center',
                                justifyContent: { xs: 'space-between', md: '' },
                                mt: { xs: 2, md: 0 },
                            }}
                        >
                            <Box
                                sx={{
                                    border: '1px solid #fff',
                                    borderRadius: 2,
                                    padding: { xs: '6px 12px', md: '7px 30px' },
                                    cursor: 'pointer',
                                }}
                                onClick={() => props.toNavigateFirstCurrency('withdraw')}
                            >
                                Withdraw
                            </Box>
                            <Box
                                sx={{
                                    border: '1px solid #fff',
                                    borderRadius: 2,
                                    padding: { xs: '6px 12px', md: '7px 30px' },
                                    cursor: 'pointer',
                                }}
                                onClick={() => props.toNavigateFirstCurrency("deposit")}
                            >
                                Deposit
                            </Box>
                        </Box>}
                </Box>
                <div className='my-4 d-flex justify-content-end align-items-center' style={{ gap: '5px' }}>
                    <div className='d-flex justify-content-start align-items-center'>
                        <button className='searchBtnFutures'><SearchIcon fontSize='sm' /></button>
                        <input type="text" className={`${foc ? 'text-light' : 'text-gray'} searchGlassFutures`} placeholder="Search Your Coin" onFocus={() => { setFoc(() => true); }} onBlur={() => {
                            setTimeout(() => {
                                setFoc(() => false);
                            }, 100);
                        }} />
                    </div>
                    <div>
                        <img src={setringImg} alt='setting' className='img-fluid' width={25} />
                    </div>
                </div>
                <TableContainer>
                    <Table sx={{ minWidth: window.innerWidth < 700 ? 1000 : 100, justifyContent: 'center' }} aria-label="simple table">
                        <StyledTableHead>
                            <TableRow>
                                <StyledTableHeadRow>Asset</StyledTableHeadRow>
                                <StyledTableHeadRow>Total Asset</StyledTableHeadRow>
                                <StyledTableHeadRow>Position Margin</StyledTableHeadRow>
                                <StyledTableHeadRow>Order Margin</StyledTableHeadRow>
                                <StyledTableHeadRow>Unrealized PNL</StyledTableHeadRow>
                                <StyledTableHeadRow>Available Balance</StyledTableHeadRow>
                                <StyledTableHeadRow>Action</StyledTableHeadRow>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            <TableRow >
                                <StyledTableCell component="th" scope="row" onClick={() => {
                                    // navigate('/spot/' + data?.pair); 
                                }} style={{ cursor: "pointer" }}>
                                    {futuresBalance?.currency}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {balShow ? futuresBalance?.availableBalance ? (+futuresBalance?.availableBalance)?.toFixed(2) : 0.00 : '****'} {futuresBalance?.currency}
                                </StyledTableCell>
                                <StyledTableCell>{balShow ? futuresBalance?.positionMargin ? (+futuresBalance?.positionMargin)?.toFixed(2) : 0.00 : '****'} {futuresBalance?.currency}</StyledTableCell>
                                <StyledTableCell>{balShow ? futuresBalance?.orderMargin ? (+futuresBalance?.orderMargin)?.toFixed(2) : 0.00 : '****'} {futuresBalance?.currency}</StyledTableCell>
                                <StyledTableCell>{balShow ? futuresBalance?.unrealisedPNL ? (+futuresBalance?.unrealisedPNL)?.toFixed(2) : 0.00 : '****'} {futuresBalance?.currency}</StyledTableCell>
                                <StyledTableCell>
                                    {balShow ? futuresBalance?.availableBalance ? (+futuresBalance?.availableBalance)?.toFixed(2) : 0.00 : '****'} {futuresBalance?.currency}
                                </StyledTableCell>
                                <StyledTableCell style={{ color: '#21CA50', fontWeight: 600 }} className='cursor-pointer'>{loadingUsdt ? <Spinner animation="border" variant="light" size="sm" /> : <TransferModalUSDT avl={(walletData?.amount || 0).toFixed(2)} firstCurrency={walletData?.currencyDetailsDB?.currencySymbol} image={walletData?.currencyDetailsDB?.image} fundMoveUSDT={fundMoveUSDT} fundUSDTLoading={fundUSDTLoading} title={'Transfer'} />}</StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </div>
    );
};

export default WalletListFutures;