import React, { useState, useEffect } from "react";
import '../assets/style.css';
import Config from "../core/config/";
import { makeRequest } from "../core/services/v1/request";
import NavbarOne from "./separate/NavbarOne";
import * as yup from 'yup';
import { Formik } from 'formik';
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { toast } from "../core/lib/toastAlert";
import $ from "jquery";
import { useContextData } from "../core/context/index";
import jwt_decode from 'jwt-decode';
import { getCookie } from "../core/helper/cookie";
import SellCryptoPro from "./SellCryptoPro";
import { showNumber } from "../core/helper/date-format";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';

const StyledTableCellFirst = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "27px",
  padding: "5px",
  color: "white",
  width: "230px !important",
  // '@media (min-width: 300px)': {
  //     // adjust the width at 600px and above
  //     width: '50px !important',
  // },
  // '@media (min-width: 960px)': {
  //     // adjust the width at 960px and above
  //     width: "50px !important",
  // },

}));
const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
  fontSize: "22px",
  fontWeight: "500",
  fontStyle: "normal",
  padding: "5px",
  paddingBottom: "16px",
  width: "auto !important",
  color: "white",
  textAlign: 'left'
  // '@media (min-width: 300px)': {
  //     // adjust the width at 600px and above
  //     width: '50px !important',
  // },
  // '@media (min-width: 960px)': {
  //     // adjust the width at 960px and above
  //     width: "50px !important",
  // },
  // lineHeight: "27px"

}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "27px",
  padding: "5px",
  width: "auto !important",
  color: "#C8C8C8",
  textAlign: 'left'
  // '@media (min-width: 300px)': {
  //     // adjust the width at 600px and above
  //     width: '50px !important',
  // },
  // '@media (min-width: 960px)': {
  //     // adjust the width at 960px and above
  //     width: "50px !important",
  // },

}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderBottom: 'none',
  fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
  fontSize: "32px",
  fontWeight: "600",
  padding: "5px",
  fontStyle: "normal",
  lineHeight: "38px",
  width: "auto !important",
  color: "white",
  textAlign: 'center'

  // '@media (min-width: 300px)': {
  //     // adjust the width at 600px and above
  //     width: '50px !important',
  // },
  // '@media (min-width: 960px)': {
  //     // adjust the width at 960px and above
  //     width: "50px !important",
  // },

}));


const validationbuySchema = yup.object({
  buycurrencyamount: yup
    .number('Please enter the Amount')
    .required('Amount is required'),
  buycurrencyaddress: yup
    .string('Please enter the Amount')
    .required('Address is required')
});

const validationsellSchema = yup.object({
  sellcurrencyamount: yup
    .number('Please enter the Amount')
    .required('Amount is required'),
  sellcurrencyaddress: yup
    .string('Please enter the Amount')
    .required('Address is required')
});
const depositAddressData = [
  { currId: '643531591490e7ffdbccea27', symbol: "BDX", address: "864xEJPqdUUXKtLVkJiVmDdEh5A1DakzQN3kwsQWZAT5cMxrftUCrcnCpn3rjdFbnafExoDd53RgYHDUVAGtgDMG4U4Kdsw", info: 'Withdraw In BDX Network Only', id: 1 },
  { currId: '640f648499ad61d2ff936aa2', symbol: "USDT", address: "0x10646DEdB5CE0D90395A19DD16034dFe9dbBbC2D", info: 'Withdraw In BEP20 Network Only', id: 3 },
  { currId: '64101bc4c406fcdd920476da', symbol: "TRX", address: "TEEPVAxBGEao1QoAKNUXmJdHbXuhugZzrB", info: 'Withdraw In TRC20 Network Only', id: 2 },
]
function Buycrypto(props) {
  const [loader, setLoader] = useState(true);
  const [walletData, setWalletData] = useState([]);
  const [INRbalance, setINRbalance] = useState("0");
  const [userid, setuserid] = useState("0");
  const [cryptobalance, setcryptobalance] = useState();
  const [buycryptoinrprice, setbuycryptoinrprice] = useState();
  const [buycurrencysymbol, setBuycurrencysymbol] = useState('643531591490e7ffdbccea27');
  const [buycurrencyamount, setBuycurrencyamount] = useState();
  const [buycryptoaddress, setbuycryptoaddress] = useState();
  const [buycryptofees, setbuycryptofees] = useState();
  const [buyfees, setbuyfees] = useState();
  const [sellcryptoinrprice, setsellcryptoinrprice] = useState();
  const [pendingorderamount, setpendingorderamount] = useState();
  const [buycurrencyaddress, setBuycurrencyaddress] = useState();
  const [spentbuycryptoinrprice, setspentbuycryptoinrprice] = useState(0);
  const [spentsellcryptoinrprice, setspentsellcryptoinrprice] = useState();
  const [spotWalletData, setSpotWalletData] = useState([]);
  const [estimatedValue, setEstimatedValue] = useState({});
  const [defaultCurrencyList, setdefaultCurrencyList] = useState({ currencySymbol: "", image: "" });
  const [isLoading, setisLoading] = useState(false);
  const [buycryptouserlist, setbuycryptouserlist] = useState([])
  const [utiprice, setutiprice] = useState();
  const { siteSettings } = useContextData();


  useEffect(() => {
    if (siteSettings != null) {
      setbuycryptofees(siteSettings['BuyCryptoFees'] + '%');
      // console.log(siteSettings['BuyCryptoExtraPriceForBuy'], "siteSettings['BuyCryptoExtraPriceForBuy']")
      try {
        const getFee = siteSettings?.BuyCryptoExtraPriceForBuyV2.find(el => el?.currency === 'BDX') || {}
        $("#fees").val(getFee?.price || 0);
      }
      catch (e) {

      }
    }

  }, [siteSettings]);

  useEffect(() => {
    getUserDetails()
    // console.log(myProfile, "asdasdasd");
    getWalletCurrency();
    getSpotWalletCurrency();
    getMyProfile();

    //getBuycryptolist();
    // if (myProfile.kycStatusDetail.aadhaar.status == 1 && myProfile.kycStatusDetail.pan.status == 1 && myProfile.kycStatusDetail.selfie.status == 1) {
    //   $('#applicant_name_Enable_hide').show();
    // } else {
    //   $('#applicant_name_Enable_hide').hide();
    // }

  }, []);

  // async function getprice(){
  //   const response = await axios('https://api.coingecko.com/api/v3/simple/price?ids=BELDEX&vs_currencies=inr');
  //   console.log(response,"price");


  // }

  async function getUserDetails() {
    const token = getCookie('userToken');
    const decodedToken = token ? jwt_decode(token, { PAYLOAD: true }) : {};
    // console.log(decodedToken.subject, "decodedToken");
    setuserid(decodedToken.subject);
  }
  async function getMyProfile() {
    const params = {
      url: `${Config.V1_API_URL}user/getMyProfile`,
      method: 'GET'
    };
    const response = await makeRequest(params);
    if (response) {
      const {
        data = [],
      } = response;
      // setuserid(response.data._id);
      const payload = {
        userID: response.data._id
      }
      // console.log(payload, "payload");
      const params2 = {
        url: `${Config.V1_API_URL}buycrypto/Userbuycryptoodrders`,
        method: 'POST',
        body: payload
      };
      const response2 = await makeRequest(params2);
      if (response2) {
        const {
          data2 = [],
        } = response2;
        // console.log(response2.data, "reponse wallet");
        setbuycryptouserlist(response2.data);


        const orderpending = response2.data.filter(element => element.status == 2);
        const newArray = []; // create an empty array to store the filtered object

        orderpending.forEach(obj => newArray.push(obj.amount)); // push the filtered object into the new array

        let sum = 0;

        for (let i = 0; i < newArray.length; i++) {
          sum += newArray[i];
        }

        setpendingorderamount(sum); // Output: 15
        localStorage.setItem("pendingbuyorder", sum);
      }


      // walletData.forEach(async (element) => {

      // if(element.currencyId== '640f6a6da85aacd35e842609'){

      // const payload = {
      //   symbol:element.currencyName.toLowerCase()
      // }
      // const params3 = {
      //  url: `${Config.V1_API_URL}buycrypto/buycryptoprice`,
      //  method: 'POST',
      //  body: payload
      //  };
      //  const response3 = await makeRequest(params3);
      //  if (response3) {
      //  const {
      //  data3 = [],
      //  } = response3;
      //  console.log(response3,"asdadasd")
      //  setsellcryptoinrprice(response3.message.toFixed(2));

      //  setsellcryptoinrprice(response3.message.toFixed(2));
      //  }
      // }
      // });
      setutiprice(localStorage.getItem('utiprice'))

    }

  }


  async function getWalletCurrency() {
    setLoader(true);
    const params = {
      url: `${Config.V2_API_URL}wallet/getWalletCurrency`,
      method: 'GET'
    };
    const response = await makeRequest(params);
    if (response) {
      const {
        status = false,
        data = [],
        estimateINR = 0,
        estimateUSD = 0,
      } = response;
      // console.log(response.data, "response wallet");
      setdefaultCurrencyList({ currencySymbol: response.data[1].currencySymbol, image: response.data[1].image })
      setWalletData(data);
      setEstimatedValue({
        estimateINR,
        estimateUSD
      });

    }
    if (buycurrencysymbol === '643531591490e7ffdbccea27') {
      const params2 = {
        url: "https://api.coindcx.com/exchange/ticker",
        method: 'GET',
      };
      const response2 = await makeRequest(params2);
      if (response2) {
        const getRes2Origin = response2.find(el => el?.market === 'BDXINR')
        let price = +(getRes2Origin?.last_price);
        const twoPercent = price * $("#fees").val(); // calculate 2% of the number
        const result = price + twoPercent;
        // console.log(result, "result") // add 2% to the number
        setBuycurrencysymbol(() => '643531591490e7ffdbccea27')
        setdefaultCurrencyList({ currencySymbol: 'BDX', image: 'https://res.cloudinary.com/dkqixjgxa/image/upload/v1681207682/Images/mejachvugmzquqd3vamu.png' })
        setbuycryptoinrprice(() => result.toFixed(2));
        setsellcryptoinrprice(() => price.toFixed(2));

      }
    }
    setLoader(false);
  }

  async function getSpotWalletCurrency() {
    const params = {
      url: `${Config.V2_API_URL}wallet/getSpotHoldings`,
      method: 'GET'
    };
    const response = await makeRequest(params);

    if (response) {
      const {
        status = false,
        data = []
      } = response;
      // console.log(data, "data");
      data.forEach(element => {
        if (element.currencySymbol == 'INR') {

          setINRbalance(element.balance);
        }
      });

      setSpotWalletData(data);

    }
  }
  const cryptobalances = async (currencySymbol) => {

    let cryptobal = '';
    spotWalletData.forEach(element => {
      if (element.currencyId == currencySymbol) {
        cryptobal = element.balance;
      }
    });

    if (currencySymbol == '') {
      spotWalletData.forEach(element => {
        if (element.currencyId == '643531591490e7ffdbccea27') {
          cryptobal = element.balance;
        }
      });
    }

    if (cryptobal == '') {
      setcryptobalance('0')
    } else {
      setcryptobalance(cryptobal)
    }


  }

  // $('#buycurrencysymbol').on('change',async function(event) {

  //   console.log(event,"events");
  //   let CurrencyID = ''
  //    //if(element._id !='643531591490e7ffdbccea29'){
  //    const payload2 = {
  //     CurrencyID:event.target.value
  //   }

  //   const params3 = {
  //     url: `${Config.V1_API_URL}buycrypto/getuserwalletaddress`,
  //     method: 'POST',
  //     body: payload2
  //     };
  //     const response3 = await makeRequest(params3);

  //     if (response3) {
  //     const {
  //     data2 = [],
  //     } = response3;

  //        setbuycryptoaddress(response3.data.address);

  //       console.log(response3.data.address,"response3");
  //     }

  //   //  }else{
  //   //    setbuycryptoaddress(0)

  //   //  }


  //   });
  async function decimalValue(value, decimal = 0) {
    if (decimal === 0) {
      return showNumber(parseFloat(value));
    }
    return showNumber(parseFloat(value).toFixed(decimal));
  }

  const handleChange = async (event) => {
    setLoader(true);
    const element = walletData.find(el => el.currencyId == event.target.value)
    if (element?.currencyId == event.target.value || event.target.value == '643531591490e7ffdbccea27') {
      setBuycurrencyamount(() => '')
      if (event.target.value == "64101d6ac406fcdd920477a0") {
        const params = {
          url: `${Config.V1_API_URL}trade/getMarketsTab?exchangeType=SPOT`,
          method: "GET",
        };
        const response = await makeRequest(params);
        if (response.status && response.data) {
          // console.log("price", response.data);
          const idx = response.data.findIndex(elem => elem.currency == "INR");

          if (idx > -1) {

            // (response.data[idx].pairs).map(data => console.log("After pair", data))
            const findUTIINR = (response.data[idx].pairs).find(data => data?.pair === "UTI_INR");
            // console.log(findUTIINR);
            // console.log("Actual price of UTI", );
            const valueUTI = await decimalValue(findUTIINR.price, findUTIINR.decimalValue);
            // console.log(typeof valueUTI, valueUTI);
            let fees = ''
            if (siteSettings) {
              fees = Number(siteSettings['BuyCryptoExtraPriceForBuy']);
            }
            const twoPercent = parseFloat(valueUTI) * fees; // calculate 2% of the number
            const result = parseFloat(valueUTI) + twoPercent; // add 2% to the number
            setbuycryptoinrprice(result?.toFixed(2));
            setBuycurrencysymbol(() => event.target.value);
            setspentbuycryptoinrprice(0);
            setLoader(false);
          }
        }
        else {
          // alert("Not exist", element.currencyName.toLowerCase());
          // console.log(element?.currencySymbol);
          toast({ type: "error", message: "Not exist" });
          setLoader(false);
        }
      }
      else {
        // const params2 = {
        //   url: `${Config.V1_API_URL}buycrypto/buycryptoprice`,
        //   method: 'POST',
        //   body: payload
        // };
        // const response2 = await makeRequest(params2);
        const params2 = {
          url: "https://api.coindcx.com/exchange/ticker",
          method: 'GET',
        };
        const response2 = await makeRequest(params2);
        if (response2) {
          let baseCurrency = event.target.value == '643531591490e7ffdbccea27' ? 'BDX' : element?.currencySymbol;
          const getRes2Origin = response2.find(el => el?.market === baseCurrency + 'INR')
          let price = +(getRes2Origin?.last_price) || 0;

          const {
            data2 = [],
          } = response2;
          let fees = ''
          if (siteSettings) {
            try {
              const getFee = siteSettings?.BuyCryptoExtraPriceForBuyV2.find(el => el?.currency === baseCurrency) || {}
              fees = Number(getFee?.price) || 0;
            }
            catch (err) {
              fees = 0;
            }
          }
          // console.log("res", response2);
          const twoPercent = price * fees; // calculate 2% of the number
          const result = price + twoPercent; // add 2% to the number
          setbuycryptoinrprice(result?.toFixed(2));
          setspentbuycryptoinrprice(0);
          setBuycurrencysymbol(() => event.target.value);
          setLoader(false);

        }
        else {
          // console.log(element?.currencySymbol);
          toast({ type: "error", message: "Not exist" });
          setLoader(false);
        }
      }
    }



  };

  // $('#sellcurrencysymbol').on('change', function (event) {
  //   $('#sellcurrencysymbol').off('change');

  //   walletData.forEach(async (element) => {
  //     // console.log(element.currencyId == event.target.value);
  //     if (element.currencyId == event.target.value) {

  //       const payload = {
  //         symbol: element.currencyName.toLowerCase()
  //       }
  //       const params2 = {
  //         url: `${Config.V1_API_URL}buycrypto/buycryptoprice`,
  //         method: 'POST',
  //         body: payload
  //       };
  //       const response2 = await makeRequest(params2);
  //       if (response2) {
  //         const {
  //           data2 = [],
  //         } = response2;
  //         let fees = ''
  //         if (siteSettings) {
  //           fees = Number(siteSettings['BuyCryptoExtraPriceForSell']);
  //         }
  //         const twoPercent = response2.message * fees; // calculate 2% of the number
  //         const result = response2.message + twoPercent; // add 2% to the number
  //         setsellcryptoinrprice(result.toFixed(2));

  //         setspentsellcryptoinrprice(0)
  //       }
  //     }
  //   });

  // });


  // $('#sellcurrencyamount').keyup(function (event) {
  //   let symbol = $('#sellcurrencysymbol option:selected').text();
  //   setspentsellcryptoinrprice(event.target.value / sellcryptoinrprice + ' ' + symbol);

  // });

  const onbuySubmit = async () => {
    if (INRbalance == 0) {
      return toast({ type: "error", message: "your Balance Is Low" });
    } else if (INRbalance <= +(buycurrencyamount)) {
      return toast({ type: "error", message: "your Balance Is Low" });
    }
    else {
      setisLoading(true);
      const element = walletData.find(el => el.currencyId == buycurrencysymbol)

      let fees = ''
      if (siteSettings) {
        fees = Number(siteSettings['BuyCryptoFees']);
      }
      const twoPercent = buycurrencyamount * fees / 100; // calculate 2% of the number
      const result = buycurrencyamount + twoPercent; // add 2% to the number

      if (element?.currencyId == buycurrencysymbol || buycurrencysymbol == '643531591490e7ffdbccea27') {

        let data = {
          userID: userid,
          currencyID: buycurrencysymbol,
          currencyname: element?.currencyName,
          currencySymbol: element?.currencySymbol,
          currencyImage: element?.image,
          currencyamount: buycurrencyamount,
          cryptoamount: spentbuycryptoinrprice,
          address: buycurrencyaddress,
          type: "Buy",
        }
        if (buycurrencysymbol == '643531591490e7ffdbccea27') {
          data.currencyname = 'Beldex'
          data.currencySymbol = 'BDX'
          data.currencyImage = 'https://res.cloudinary.com/dkqixjgxa/image/upload/v1681207682/Images/mejachvugmzquqd3vamu.png'
        }
        // console.log(data, "data", buycurrencyaddress);
        if (!buycurrencyamount || !buycurrencyaddress) {
          setisLoading(false);
          return toast({ type: 'error', message: 'Fill Up All Details' })
        }
        if (!data?.userID || !data?.currencyID || !data?.currencyname || !data?.cryptoamount || !data?.currencyamount || !data?.address) {
          setisLoading(false);
          return toast({ type: 'error', message: 'Something went wrong' })
        }

        const params = {
          url: `${Config.V1_API_URL}buycrypto/buysubmit`,
          method: 'POST',
          body: data
        }
        const response = (await makeRequest(params));
        setisLoading(false);
        let type = "error";
        if (response.status) {
          type = 'success';

          toast({ type, message: response.message });

          setInterval(function () { window.location.reload() }, 3000);

        } else {
          toast({ type, message: response.message });
          setInterval(function () { window.location.reload() }, 3000);
        }

        setisLoading(false);

      }
    }
  }

  // const onsellSubmit = async (values) => {
  //   // console.log(cryptobalance, "setcryptobalance");
  //   if (cryptobalance == 0) {
  //     toast({ type: "error", message: "your Balance Is Low" });
  //   } else {

  //     setisLoading(true);
  //     let data = {}
  //     spotWalletData.forEach(element => {

  //       if (element.currencyId == values.sellcurrencysymbol) {
  //         data = {
  //           userID: userid,
  //           currencyID: values.sellcurrencysymbol,
  //           currencyamount: values.sellcurrencyamount,
  //           address: values.sellcurrencyaddress,
  //           currencyname: element.currencyName,
  //           currencySymbol: element.currencySymbol,
  //           currencyImage: element.image,
  //           type: "Sell",
  //         }
  //       }
  //     });


  //     const params = {
  //       url: `${Config.V1_API_URL}buycrypto/sellsubmit`,
  //       method: 'POST',
  //       body: data
  //     }
  //     const response = (await makeRequest(params));
  //     setisLoading(false);
  //     let type = "error";
  //     if (response.status) {
  //       type = 'success';

  //       toast({ type, message: response.message });
  //     } else {
  //       toast({ type, message: response.message });
  //     }

  //     setisLoading(false);

  //   }


  // }
  async function copyContent(text) {
    try {
      await navigator.clipboard.writeText(text);
      let type = "success";
      toast({ type, message: "Copied" });
    } catch (err) {
      console.error('Failed to copy: ', err);
      let type = "error";
      toast({ type, message: "Failed" });
    }
  }
  return (


    <div className="container pt-3">

      <div className='buy-box mx-auto my-4'>
        <input type="hidden" id="fees"></input>
        <Form className='mx-auto pt-3' style={{ width: '90%' }}>
          <Form.Group className="my-3" controlId="exampleForm.ControlInput1">

            <div className="custom2-select-wrapper">
              <Form.Select aria-label="Default select example" className="trade-input" onChange={handleChange} name="buycurrencysymbol" disabled={loader} required>
                <option value="643531591490e7ffdbccea27" >BDX</option>
                <option value="640f648499ad61d2ff936aa2" >USDT</option>
                <option value="64101bc4c406fcdd920476da" >TRX</option>
                {/* {walletData.map(({ currencySymbol, currencyId }, index) => {
                                if (currencySymbol != 'INR'&&currencySymbol != 'BDX'  && currencySymbol != 'INR' && currencySymbol != 'MATIC' && currencySymbol != 'BTC' && currencySymbol != 'USDT' && currencySymbol != 'SHIB' && currencySymbol != 'BUSD' && currencySymbol != 'RACA' && currencySymbol != 'AVAX' && currencySymbol != 'TWT' && currencySymbol != 'XEC') {
                                  if(currencySymbol=='TRX'){
                                    console.log(currencyId)
                                  }
                                  return (
                                    <option value={currencyId} >{currencySymbol}</option>
                                  )
                                }
                              }
                              )} */}
              </Form.Select>
              <div className="custom2-select-arrow"></div>
            </div>
          </Form.Group>
          <Form.Group className="mb-3 inputcontainer" controlId="exampleForm.ControlInput1">

            <Form.Control type="text" value={`Price: ${loader ? '' : buycryptoinrprice}`} className='trade-input' readOnly required />
            {(loader) ? <div className="icon-container">
              <Spinner animation="border" size="sm" />
            </div> : null}
          </Form.Group>

          <InputGroup className="mb-3">
            <InputGroup.Text className='trade-input' >Enter  Amount</InputGroup.Text>
            <Form.Control className='trade-input' type="number"
              placeholder="EnterAmount"
              aria-label="EnterAmount"
              name="buycurrencyamount"
              required
              value={buycurrencyamount}
              onChange={(event) => {
                setBuycurrencyamount(event.target.value)
                let fees = ''
                if (siteSettings) {
                  fees = siteSettings['BuyCryptoFees'];
                }
                let percentage = event.target.value / buycryptoinrprice * fees / 100;

                let amount = event.target.value / buycryptoinrprice - percentage;

                let symbol = $('#buycurrencysymbol option:selected').text();


                // console.log(fees, "fees");
                setspentbuycryptoinrprice((amount).toFixed(6) + ' ' + symbol);
              }} />
            <InputGroup.Text className='trade-input' >INR</InputGroup.Text>
          </InputGroup>
          {/* {errors.buycurrencyamount ? <small className="invalid-buycurrencyamount error">{errors.buycurrencyamount}</small> : null} */}
          {
            (() => {
              if (INRbalance) {
                return (
                  <p className='py-0 mb-2 text-white' style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }}>INR Balance: {Number(INRbalance).toFixed(2)}</p>
                )
              }
            })()
          }

          <InputGroup className="mb-2 pb-1">
            <InputGroup.Text className='trade-input' >Enter  Address</InputGroup.Text>
            <Form.Control className='trade-input' type='text' class="form-control border-0 buycrypto-select-bg-styling"
              placeholder="Enter Address" aria-label="EnterAmount"
              name="buycurrencyaddress" value={buycurrencyaddress}
              required
              onChange={(e) => setBuycurrencyaddress(e.target.value)} />
          </InputGroup>
          {/* {errors.buycurrencyaddress ? <small className="invalid-buycurrencyamount error">{errors.buycurrencyaddress}</small> : null} */}
          <div style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }} className=''>
            <p><small>
              {
                (() => {
                  let current = depositAddressData.find(el => el.currId === buycurrencysymbol);
                  return current?.info;
                })()
              }</small></p>
          </div>
          <div style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }} className='d-flex justify-content-between align-items-center'>
            <p>You Get : {spentbuycryptoinrprice}</p>
            <p>Fees + GST: {buycryptofees}</p>
          </div>
          <div className='mx-auto text-center my-3'>
            <button className='btn-buy px-5 mb-3' type="button" onClick={() => onbuySubmit()} disabled={isLoading}>BUY</button>
          </div>

        </Form>
      </div>
      {buycryptouserlist.length > 0 &&
        <div className='my-5 pt-4'>
          <TableContainer >
            <Table sx={{ minWidth: window.innerWidth < 700 ? 1050 : 100, justifyContent: 'center' }} aria-label="simple table" >
              <StyledTableHead>
                <TableRow >
                  <StyledTableHeadRow>#</StyledTableHeadRow>
                  <StyledTableHeadRow>Currency</StyledTableHeadRow>
                  <StyledTableHeadRow>Address</StyledTableHeadRow>
                  <StyledTableHeadRow>Amount</StyledTableHeadRow>
                  <StyledTableHeadRow>Crypto Amount</StyledTableHeadRow>
                  <StyledTableHeadRow>Type</StyledTableHeadRow>
                  <StyledTableHeadRow>Status</StyledTableHeadRow>
                  <StyledTableHeadRow>Date</StyledTableHeadRow>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {buycryptouserlist.length > 0 && buycryptouserlist.map((row, i) => {
                  if (row) {
                    return (
                      <TableRow key={i}>
                        <StyledTableCell >
                          {i + 1}
                        </StyledTableCell>
                        <StyledTableCell><span className='d-flex justify-content-start align-items-cneter' ><img src={row?.currencyImage} alt="" style={{ width: "30px" }} /><span className='text-light ps-2'> {row?.currencySymbol}</span></span></StyledTableCell>
                        <StyledTableCell style={{ cursor: 'pointer' }} onClick={() => copyContent(row?.address)}>{row?.address?.slice(0, 10) + '..xx'}</StyledTableCell>
                        <StyledTableCell>{row?.amount}</StyledTableCell>
                        <StyledTableCell>{row?.cryptoamount}</StyledTableCell>
                        <StyledTableCell>{row?.type}</StyledTableCell>
                        <StyledTableCell>{row.status == 0 ? 'Rejected' : row.status == 1 ? 'Sucess' : 'Pending'}</StyledTableCell>
                        <StyledTableCell>{row?.dateTime}</StyledTableCell>
                      </TableRow>
                    )
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      }

    </div>
  );
}

export default Buycrypto;