import React from 'react';
import SpotUsdt from './Spot-usdt';
import { useNavigate, useParams } from 'react-router-dom';

const SpotUsdtWrap = () => {
    const { pairName } = useParams();
    const navigate = useNavigate();
    const handleNavigation = (newSymbol) => {
        navigate('/spot-usdt/' + newSymbol);
    };
    return (
        <div>
            <SpotUsdt navigate={navigate} handleNavigation={handleNavigation} key={pairName} pairName={pairName} />
        </div>
    );
};

export default SpotUsdtWrap;