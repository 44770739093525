import React, { useState } from 'react';
import ButtonB from "react-bootstrap/Button";
import { BiSort } from "react-icons/bi";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import '../../assets/style.css'
import {
    Box,
    Modal as ModalMUI,
    Typography,
} from '@mui/material'
import { useContextData } from '../../core/context';
import { toast } from '../../core/lib/toastAlert';
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 300, md: 400 },
    bgcolor: '#2E2C2C',
    boxShadow: 24,
    padding: { xs: '10px', md: '25px 15px' },
    borderRadius: 2,
}
const TransferModalUSDT = ({ avl, firstCurrency, image, fundUSDTLoading, fundMoveUSDT, isFutures=false, title='Transfer' }) => {
    const [spotUsdt, setSpotUsdt] = useState(null)
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [fromAccount, setFromAccount] = useState('spot inr');
    const [toAccount, setToAccount] = useState('spot usdt');
    const { spotBalance, futuresBalance } = useContextData();

    const subTransfer = async () => {
        if (parseFloat(spotUsdt) < 1) {
            return toast({ type: 'error', message: 'Minimum 1 USDT is Required' })
        }
        await fundMoveUSDT(parseFloat(spotUsdt), fromAccount, toAccount)
        setSpotUsdt(() => null)
        handleClose()
    }

    return (
        <div>
            <span onClick={handleOpen} className='cursor-pointer'>{title}</span>
            <ModalMUI
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={modalStyle}>
                    <Typography
                        sx={{
                            color: '#fff',
                            fontSize: { xs: 15, md: 22 },
                            fontWeight: 'bold',
                            paddingBottom: 1
                        }}
                    >
                        Transfer{' '}
                    </Typography>

                    <Box sx={{ marginBottom: { xs: 1, md: 2 } }}>
                        <Box
                            sx={{
                                bgcolor: '#535151',
                                p: { xs: 1.5, md: 2.5 },
                                borderRadius: { xs: 3, md: 6 },
                                color: '#fff',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: '55px'
                                }}
                            >
                                <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>From</Typography>
                                <Box sx={{ position: 'relative', top: '5px' }}>
                                    <select className='fundTransPopUp' aria-label="fromaccount" onChange={e => setFromAccount(() => e.target.value)} value={fromAccount}>
                                        <option className='d-none'>Open this select menu</option>
                                        <option hidden={toAccount === 'spot inr'} value="spot inr">Spot INR</option>
                                        <option hidden={toAccount === 'spot usdt'} value="spot usdt">Spot USDT</option>
                                        <option hidden={toAccount === 'futures usdt'} value="futures usdt">Futures USDT</option>
                                    </select>
                                </Box>
                                {/* <KeyboardArrowDownIcon /> */}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginY: { xs: 1, md: 2 },
                                }}
                            >
                                <Box className='cursor-pointer' onClick={() => {
                                    let fromAccStore = fromAccount;
                                    setFromAccount(() => toAccount)
                                    setToAccount(() => fromAccStore)
                                }}>
                                    {/* {fromAccount === 'spot' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />} */}
                                    <BiSort fontSize={25} />
                                </Box>
                                <Box
                                    sx={{ width: '85%', height: '2px', bgcolor: '#fff' }}
                                ></Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: '75px'
                                }}
                            >
                                <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>To</Typography>
                                <Box sx={{ position: 'relative', top: '-5px' }}>
                                    <select className='fundTransPopUp' aria-label="toaccount" onChange={e => setToAccount(() => e.target.value)} value={toAccount}>
                                        <option className='d-none'>Open this select menu</option>
                                        <option hidden={fromAccount === 'spot inr'} value="spot inr">Spot INR</option>
                                        <option hidden={fromAccount === 'spot usdt'} value="spot usdt">Spot USDT</option>
                                        <option hidden={fromAccount === 'futures usdt'} value="futures usdt">Futures USDT</option>
                                    </select>
                                </Box>
                                {/* <KeyboardArrowDownIcon /> */}
                            </Box>
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            color: '#fff',
                            marginBottom: 1,
                            fontSize: { xs: '12px', md: '16px' },
                        }}
                        id='demo-simple-select-label'
                    >
                        Batch Transfer
                    </Typography>
                    <Box>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon2" style={{
                                backgroundColor: '#535151',

                                color: 'white',
                                // border: 'none',
                                padding: '10px',
                                borderRadius: '25px 0px 0px 25px',
                                // '&::before': {
                                //     border: 'none',
                                // },
                            }}><img src={image} alt='coin' className='img-fluid' width={window.innerWidth > 650 ? 30 : 25} /></InputGroup.Text>
                            <Form.Control
                                placeholder={firstCurrency}
                                value={firstCurrency}
                                readOnly
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                style={{
                                    backgroundColor: '#535151',
                                    fontSize: window.innerWidth > 650 ? '1rem' : '0.8rem',
                                    color: 'white',
                                    // border: 'none',
                                    padding: '12px',
                                    borderRadius: '0px 25px 25px 0px',
                                    // '&::before': {
                                    //     border: 'none',
                                    // },
                                }}
                            />
                        </InputGroup>

                    </Box>
                    <Box
                        sx={{
                            marginY: { xs: 1, md: 2 },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'Space-between',
                                alignContent: 'center',
                            }}
                        >
                            <Typography
                                sx={{ color: '#fff', fontSize: { xs: '12px', md: '16px' } }}
                            >
                                Amount
                            </Typography>
                            <Typography sx={{ color: '#C8C8C8', fontSize: '10px', textAlign: 'end' }}>
                                {fromAccount === 'spot usdt' ? <>Spot USDT : {spotBalance?.trade?.[firstCurrency]?.available.toFixed(3) || 0.00} {firstCurrency}{' '}</> : fromAccount === 'spot inr' ? <>Spot INR: {(avl || 0)} {firstCurrency}{' '}</> : fromAccount === 'futures usdt' ? <>Futures USDT: {futuresBalance?.availableBalance ? (+futuresBalance?.availableBalance).toFixed(2) : 0.00} USDT{' '}</> : <></>}
                            </Typography>

                        </Box>
                        <InputGroup className="mb-3">
                            <Form.Control
                                value={spotUsdt}
                                onChange={(e) => setSpotUsdt(() => e.target.value)}
                                placeholder="USDT"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                style={{
                                    backgroundColor: '#535151',
                                    fontSize: window.innerWidth > 650 ? '1rem' : '0.8rem',
                                    color: 'white',
                                    border: 'none',
                                    padding: '12px',
                                    borderRadius: '25px 0px 0px 25px',
                                    '&::before': {
                                        border: 'none',
                                    },
                                }}

                            />
                            <ButtonB variant="light" className='border text-primary' style={{ borderRadius: '0px 25px 25px 0px', }} onClick={() => setSpotUsdt(() => {

                                if (fromAccount === 'spot inr') {
                                    return avl
                                }
                                else if (fromAccount === 'spot usdt') {
                                    return +(spotBalance?.trade?.[firstCurrency]?.available.toFixed(3) || 0)
                                }
                                else if (fromAccount === 'futures usdt') {
                                    return +(futuresBalance?.availableBalance || 0).toFixed(2)
                                }
                            })}>Max</ButtonB>
                        </InputGroup>
                        <div className='d-flex justify-content-between align-items-start'>
                            <Typography sx={{ color: '#C8C8C8', fontSize: '10px' }}>
                                Min: 1 USDT
                            </Typography>
                            <Typography sx={{ color: '#C8C8C8', fontSize: '10px' }}>
                                {toAccount === 'spot usdt' ? <>Spot USDT : {spotBalance?.trade?.[firstCurrency]?.available.toFixed(3) || 0.00} {firstCurrency}{' '}</> : toAccount === 'spot inr' ? <>Spot INR: {(avl || 0)} {firstCurrency}{' '}</> : toAccount === 'futures usdt' ? <>Futures USDT: {futuresBalance?.availableBalance ? (+futuresBalance?.availableBalance).toFixed(2) : 0.00} USDT{' '}</> : <></>}
                            </Typography>
                        </div>
                    </Box>
                    <button
                        className='confirm-btn-transfer mt-2'
                        onClick={() => subTransfer()}
                        disabled={fundUSDTLoading}
                    >
                        {fundUSDTLoading ? 'Loading' : 'Confirm'}
                    </button>
                </Box>
            </ModalMUI>
        </div>
    );
};

export default TransferModalUSDT;