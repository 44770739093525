import React from 'react';

const SpotBelowInfoUsdt = ({ tradePreliminary, pairDetails,selectedItem }) => {

    return (
        <div className='d-flex justify-content-between align-items-center overflow-hidden'>
            <div style={{ width: '100%' }} className={`${selectedItem==='sell'?'d-none':''}`}>
                <button onClick={() => tradePreliminary('buy', 'long')}>Buy {pairDetails?.data?.baseCurrency}</button>
            </div>
            <div style={{ width: '100%' }} className={`${selectedItem==='buy'?'d-none':''}`}>
                <button onClick={() => tradePreliminary('sell', 'short')}>Sell {pairDetails?.data?.baseCurrency}</button>
            </div>
        </div>
    );
};

export default SpotBelowInfoUsdt;