import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import { countDecimals } from '../../../../core/helper/common';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        borderTop: '1px solid rgba(200, 200, 200, 0.32)',
        borderBottom: '1px solid rgba(200, 200, 200, 0.32)'
    },
    '& .MuiDialogTitle-root': {
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.975rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialog-paper': {
        borderRadius: '8px',
        background: '#2E2C2C',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    }
}));

export default function CloseConfirmationUsdt({ elem, closePosition, getCurr, size, fromSymbolName }) {
    const { symbol = '',
        realLeverage = 0,
        currentQty = 0,
        currentCost = 0,
        avgEntryPrice = 0,
        markPrice = 0,
        liquidationPrice = 0,
        posInit = 0,
        unrealisedPnl = 0,
        realisedPnl = 0, } = elem;
    const [price, setPrice] = React.useState(null);
    const [amount, setAmount] = React.useState(null);
    const [tab, setTab] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',

        },
    });
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setAmount(() => null)
        setPrice(() => null)
    };
    const getClose = (resTab) => {
        const getPair = getCurr?.pair
        const minSize = getPair?.multiplier * getPair?.lotSize;
        const result = (1 * parseFloat(amount)) / parseFloat(minSize);
        // const roundedResult = Math.round(result * 1000000) / 1000000;
        const getDecimal = countDecimals(minSize)
        let tradeParams = {
            side: currentQty > 0 ? 'sell' : 'buy', //buy sell
            symbol: symbol,
            leverage: realLeverage,
            type: resTab == 1 ? "limit" : "market",
            size: (result % 1 === 0) ? result.toString() : result.toFixed(getDecimal).toString()
        }
        if (resTab == 1) {
            tradeParams.price = price.toString()
        }
        // console.log(roundedResult.toString());
        closePosition(tradeParams);
    }
    const handleAmountChange = (e) => {
        const value = e.target.value;
        const getPair = getCurr?.pair;
        const getDecimal = countDecimals(getPair?.multiplier * getPair?.lotSize)
        const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,${getDecimal}}$`);
        if (regex.test(value) || value === '') {
            setAmount(() => value);
        }
    };
    const handlePriceChange = (e) => {
        const value = e.target.value;
        const getPair = getCurr?.pair;
        const getDecimal = countDecimals(getPair?.tickSize)
        const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,${getDecimal}}$`);
        if (regex.test(value) || value === '') {
            setPrice(() => value);
        }
    };
    const getAmountPercentage = (how = 0, total = 0) => {

        if (total < 0) {
            total *= -1
        }
        const getPair = getCurr?.pair
        const minSize = getPair?.multiplier * getPair?.lotSize
        const getDecimal = countDecimals(minSize)
        const percentage = how / 100;
        let result = (+total) * percentage;
        let resultDecimal = countDecimals(result);
        
        if (resultDecimal > getDecimal) {
            result = result.toFixed(getDecimal)
        }
        if (result < minSize) {
            return setAmount(() => 0)
        }
        setAmount(() => result)

    }
    const buttons = [
        <Button sx={{
            '&:hover': {
                border: 'none',
            }, mr: 2, color: (theme) => theme.palette.grey[400], bgcolor: (theme) => theme.palette.grey[800], border: 'none'
        }} key="25" onClick={() => getAmountPercentage(25, size)}>25%</Button>,
        <Button sx={{
            '&:hover': {
                border: 'none',
            }, mr: 2, color: (theme) => theme.palette.grey[400], bgcolor: (theme) => theme.palette.grey[800], border: 'none'
        }} key="50" onClick={() => getAmountPercentage(50, size)}>50%</Button>,
        <Button sx={{
            '&:hover': {
                border: 'none',
            }, mr: 2, color: (theme) => theme.palette.grey[400], bgcolor: (theme) => theme.palette.grey[800], border: 'none'
        }} key="75" onClick={() => getAmountPercentage(75, size)}>75%</Button>,
        <Button sx={{
            '&:hover': {
                border: 'none',
            }, mr: 0, color: (theme) => theme.palette.grey[400], bgcolor: (theme) => theme.palette.grey[800], border: 'none'
        }} key="100" onClick={() => getAmountPercentage(100, size)}>100%</Button>,
    ];
    return (
        <React.Fragment>
            <ThemeProvider theme={darkTheme}>
                <button type="button" className="market-trade-button px-2" onClick={() => handleClickOpen()}>Close</button>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Close {fromSymbolName(getCurr?.pair?.baseCurrency)}{getCurr?.pair?.quoteCurrency}
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <Box>
                            <Button sx={{
                                '&:hover': {
                                    border: 'none',
                                }, mr: 2, color: tab == 1 ? 'rgb(13, 110, 253)' : (theme) => theme.palette.grey[400], bgcolor: tab == 1 ? '#f8f9fa' : (theme) => theme.palette.grey[800], border: 'none'
                            }} onClick={() => setTab(() => 1)}>Limit</Button>
                            <Button sx={{
                                '&:hover': {
                                    border: 'none',
                                }, mr: 2, color: tab == 2 ? 'rgb(13, 110, 253)' : (theme) => theme.palette.grey[400], bgcolor: tab == 2 ? '#f8f9fa' : (theme) => theme.palette.grey[800], border: 'none'
                            }} onClick={() => setTab(() => 2)}>Market</Button>
                        </Box>
                        <Box
                            component="form"
                            sx={{ width: '100%' }}
                            noValidate
                            autoComplete="off"
                        >
                            {tab == 1 ? <TextField value={price} onChange={(e) => handlePriceChange(e)} variant="outlined" placeholder='Price' sx={{ mt: 2, mb: 0, width: '100%' }} type='number' size="small" /> : null}
                            <TextField value={amount} sx={{ mt: 2, mb: 2, width: '100%' }} onChange={(e) => handleAmountChange(e)} placeholder={`Amount (${getCurr?.baseCurrency})`} variant="outlined" type='number' size="small" />
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                '& > *': {
                                    m: 1,
                                },
                            }}
                        >
                            <ButtonGroup aria-label="Small button group">
                                {buttons}
                            </ButtonGroup>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <button className='btn-cancel' onClick={handleClose}>
                            Cancel
                        </button>
                        <button className='btn-confirm' disabled={size == 0} onClick={() => { getClose(tab); handleClose() }}>

                            Confirm
                        </button>
                    </DialogActions>
                </BootstrapDialog>
            </ThemeProvider>
        </React.Fragment >
    );
}