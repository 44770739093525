import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import React, { useEffect, useState } from 'react'
import PageTitleDes from '../../core/support/PageTitleDes'
import NavbarOne from '../../Pages/separate/NavbarOne'
import { Box, Typography, Grid, Tabs, Tab } from '@mui/material'
import socketIOClient from 'socket.io-client'
import Config from '../../core/config'
import { makeRequest } from '../../core/services/v1/request'
import { decimalValue, formatDate } from '../../core/helper/date-format'
import TargetMenu from './Target'
import { useContextData } from '../../core/context'
import DoneIcon from '@mui/icons-material/Done'
import '../../assets/style.css'
import { useNavigate } from 'react-router-dom'
import { toast } from '../../core/lib/toastAlert'
import Loader from '../../Component/Loader/Loader'
import { Helmet } from 'react-helmet-async';

const Signals = () => {
  const navigate = useNavigate()
  const [value, setValue] = useState(0)
  const [coins, setCoins] = useState({ USDT: [], INR: [] })
  const [futuresCoin, setFuturesCoin] = useState([])
  const [socketConnection, setSocketConnection] = useState(null)
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const [closedData, setClosedData] = useState([])
  const [utiLoading, setUTILoading] = useState(true)
  const { coindcxDecimal, spotPnl, myProfile, profileLoading } = useContextData()
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const getUTIBalance = async () => {
    try {
      const params = {
        url: `${Config.V2_API_URL}wallet/getUNITICBalance`,
        method: 'GET'
      };
      const response = await makeRequest(params);
      if (response) {
        const actual = (response?.data?.amount || 0) + (response?.data?.hold || 0)
        if (actual < 1000) {
          toast({
            type: "error",
            message: "Hold 1000 UNITIC Tokens to Access Signals",
          });
          navigate("/my/dashboard");
        }
      }
      setUTILoading(() => false)
    }
    catch (e) {
      setUTILoading(() => false)
    }
  }
  useEffect(() => {
    getUTIBalance();
    // setUTILoading(() => false)
    if (!utiLoading && !profileLoading && (myProfile?.kycstatus != 1 || myProfile?.kycOffline?.aml?.status != 1)) {
      toast({
        type: "error",
        message: "Please complete your KYC to continue.",
      });
      navigate("/my/identification");
    }
  }, [myProfile, profileLoading, utiLoading]);

  const getSignalData = async () => {
    const getURL = Config.V1_API_URL + 'spot-signal/get-active-spot-signal'
    const params = {
      url: getURL,
      method: 'GET',
    }
    const submit = await makeRequest(params)
    if (submit.success) {
      setData(() => submit?.data)
    }
  }
  const getSignalData2 = async () => {
    const getURL = Config.V1_API_URL + 'futures-signal/get-active-futures-signal'
    const params = {
      url: getURL,
      method: 'GET',
    }
    const submit = await makeRequest(params)
    if (submit.success) {
      setData2(() => submit?.data)
    }
  }
  const getSignalClosedData = async () => {
    const getURL = Config.V1_API_URL + 'spot-signal/get-result-spot-signal'
    const params = {
      url: getURL,
      method: 'GET',
    }
    const submit = await makeRequest(params)
    if (submit.success) {
      setClosedData(() => submit?.data)
    }
  }
  const getCoins = async () => {
    const params = {
      url: `${Config.V1_API_URL}trade/getMarketsTab?exchangeType=SPOT`,
      method: 'GET',
    }
    const response = await makeRequest(params)
    if (response.status && response.data) {
      const getInr = response.data.find((data) => data?.currency === 'INR')
      const getUsdt = response.data.find((data) => data?.currency === 'V2USDT')
      setCoins(() => ({
        USDT: getUsdt?.pairs,
        INR: getInr?.pairs,
      }))
    }
  }
  const getFuturesCoins = async () => {
    const params = {
      url: `${Config.V1_API_URL}futures-signal/get-futures-prices`,
      method: 'GET',
    }
    const response = await makeRequest(params)
    if (response.status && response.data) {
      setFuturesCoin(() => response.data)
    }
  }
  useEffect(() => {
    getSignalData()
    getSignalData2()
    getSignalClosedData()
    getCoins()
    // getFuturesCoins()
    let socket = socketIOClient(Config.SOCKET_URL, {
      // transports: ["polling"],
      transports: ['websocket'],
      // rejectUnauthorized: false
    })
    let socketUnsubscribe
    if (socket) {
      socket.on('connect', function () {
        setSocketConnection(socket)
        socketUnsubscribe = socket
      })
      socket.on('connect_error', (err) => {
        console.log('socket connect_error', err)
      })
      socket.on('disconnect', function () {
      })
    }
    return () => {
      if (socketUnsubscribe) {
        socketUnsubscribe.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    if (socketConnection != null) {
      // loadSocket()
    }
  }, [socketConnection])

  function loadSocket() {
    socketConnection.emit('getActiveSpotSignals', {})
    socketConnection.emit('getClosedSpotSignals', {})
    socketConnection.emit('getMarketSpotSignals', {})
    socketConnection.on('get-active-spot-signal', function (data) {
      setData(() => data)
    })
    socketConnection.on('get-closed-spot-signal', function (data) {
      setClosedData(() => data)
    })
    socketConnection.on('get-market-spot-signal', function (data) {
      const getInr = data.find((data) => data?.currency === 'INR')
      const getUsdt = data.find((data) => data?.currency === 'USDT')
      setCoins(() => ({
        USDT: getUsdt?.pairs,
        INR: getInr?.pairs,
      }))
    })
  }
  const percentageFunc = (tp, ep) => {
    return Math.abs(((tp - ep) / ep) * 100).toFixed(2)
  }
  const percentageFuturesLongFunc = (price, ePrice, leverage) => {
    ePrice = parseFloat(ePrice);
    price = parseFloat(price);
    leverage = parseFloat(leverage);
    if (!ePrice || !price || !leverage) {
      return 0.00
    }
    const getNetProfitValue = (((price - ePrice) / ePrice) * 100) * leverage;
    return getNetProfitValue.toFixed(2) || 0.00;
  }
  const percentageFuturesShortFunc = (price, ePrice, leverage) => {
    ePrice = parseFloat(ePrice);
    price = parseFloat(price);
    leverage = parseFloat(leverage);
    if (!ePrice || !price || !leverage) {
      return 0.00
    }
    const getNetProfitValue = (((-price - (-ePrice)) / ePrice) * 100) * leverage;
    return getNetProfitValue.toFixed(2) || 0.00;
  }
  if (profileLoading || utiLoading) {
    return <Loader />
  }
  return (
    <div>
      <div className='min-vh-100 bg-default  text-white bg-particular'>
        <PageTitleDes
          title={`Buy Bitcoin & Cryptocoin in India at the best price on UniticExchange`}
          description={`Buy BTC to INR, UTI Coin & Other Cryptocoin in India at the price on uniticexchange`}
        />
        <Helmet>
          <link rel="canonical" href="https://uniticexchange.com/signal" />
        </Helmet>
        <NavbarOne />
        <Box
          sx={{
            color: '#fff',
            padding: { xs: 2, xl: 2 },
            pt: { xs: 4, xl: 4 },
            // maxWidth: "1500px",
            marginX: 'auto',
          }}
        >
          <Box
            sx={{
              // display: "none",
              bgcolor: '#3E3E3E',
              padding: '15px 10px 12px 10px',
              borderRadius: 2,
              overflow: 'auto',
              width: '100%',

            }}
          >
            <Typography className='text-start text-sm-start text-md-center' component='h1'>Signals Profit rate</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: { xs: 1, sm: 3 },
                textAlign: 'center',
                // overflow: 'auto',
                minWidth: 'fit-content',
                mt: 2,
                flexWrap: 'nowrap',
              }}
            >
              <Box>
                <Typography sx={{ fontSize: { xs: '10px', sm: '16px' } }}>7D</Typography>
                <Typography
                  sx={{
                    color: spotPnl?.seven?.pnl >= 0 ? '#21CA50' : '#ff4b5e',
                    fontSize: { xs: '10px', sm: '14px' },
                    whiteSpace: 'nowrap',
                  }}
                >
                  {spotPnl?.seven?.pnl > 0
                    ? `+${spotPnl?.seven?.pnl}`
                    : spotPnl?.seven?.pnl}
                  %
                </Typography>
              </Box>

              <Box>
                <Typography sx={{ fontSize: { xs: '10px', sm: '16px' } }}>1M</Typography>
                <Typography
                  sx={{
                    color: spotPnl?.thirty?.pnl > 0 ? '#21CA50' : '#ff4b5e',
                    fontSize: { xs: '10px', sm: '14px' }, whiteSpace: 'nowrap',
                  }}
                >
                  {spotPnl?.thirty?.pnl > 0
                    ? `+${spotPnl?.thirty?.pnl}`
                    : spotPnl?.thirty?.pnl}
                  %
                </Typography>
              </Box>

              <Box>
                <Typography sx={{ fontSize: { xs: '10px', sm: '16px' } }}>3M</Typography>
                <Typography
                  sx={{
                    color: spotPnl?.ninety?.pnl >= 0 ? '#21CA50' : '#ff4b5e',
                    fontSize: { xs: '10px', sm: '14px' }, whiteSpace: 'nowrap',
                  }}
                >
                  {spotPnl?.ninety?.pnl > 0
                    ? `+${spotPnl?.ninety?.pnl}`
                    : spotPnl?.ninety?.pnl}
                  %
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: { xs: '10px', sm: '16px' } }}>6M</Typography>
                <Typography
                  sx={{
                    color: spotPnl?.oneEighty?.pnl >= 0 ? '#21CA50' : '#ff4b5e',
                    fontSize: { xs: '10px', sm: '14px' }, whiteSpace: 'nowrap',
                  }}
                >
                  {spotPnl?.oneEighty?.pnl > 0
                    ? `+${spotPnl?.oneEighty?.pnl}`
                    : spotPnl?.oneEighty?.pnl}
                  %
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: { xs: '10px', sm: '16px' } }}>1Y</Typography>
                <Typography
                  sx={{
                    color: spotPnl?.threeHundredSixty?.pnl >= 0 ? '#21CA50' : '#ff4b5e',
                    fontSize: { xs: '10px', sm: '14px' }, whiteSpace: 'nowrap',
                  }}
                >
                  {spotPnl?.threeHundredSixty?.pnl > 0
                    ? `+${spotPnl?.threeHundredSixty?.pnl}`
                    : spotPnl?.threeHundredSixty?.pnl}
                  %
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: { xs: '100%', sm: '90%' },
              height: 'auto',
              marginLeft: 'auto',
              marginRight: 'auto',
              bgcolor: '#4D4D4D',
              borderRadius: '10px',
              color: '#C8C8C8',
              padding: '10px',
            }}
            className='my-3'
          >
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              sx={{
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
                '& .MuiTab-root': {
                  color: 'white',
                  textTransform: 'capitalize',
                  fontSize: { xs: '12px', sm: '18px' },
                  fontWeight: '500',
                  fontFamily:
                    'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                  fontStyle: 'normal',
                  paddingLeft: { xs: '10px', sm: '16px' },
                  paddingRight: { xs: '10px', sm: '16px' },
                  marginLeft: { xs: '5px', sm: '10px' },
                  marginRight: { xs: '5px', sm: '10px' },
                  minWidth: { xs: '60px', sm: '90px' },
                },
                '& .MuiTab-root.Mui-selected': {
                  background: '#21CA50',
                  color: 'white',
                  fontWeight: 'bold',
                  borderRadius: { xs: 10, sm: 50 },
                  //   paddingX: 8,
                },
              }}
            >
              <Tab label={<h2 style={{ margin: 0 }} className='tabFontSize'>Spot</h2>} />
              <Tab label={<h3 style={{ margin: 0 }} className='tabFontSize'>Futures</h3>} />
              <Tab label='Results' />
            </Tabs>
          </Box>

          {value === 0 ? (
            //Spot Tab
            <Box sx={{ marginY: 4 }}>
              {data?.length <= 0 && (
                <h4 className='text-center'>No Signals Yet....!!!!!</h4>
              )}
              <Grid container spacing={2}>
                {data &&
                  data.map((e, index) => {
                    let coindcxDecimalPair =
                      coindcxDecimal[`${e?.coin}${e?.currency}`]
                    let getPrice
                    if (e?.currency === 'INR') {
                      try {
                        getPrice = coins?.INR.find((data) =>
                          (data?.pair).includes(e?.coin)
                        )
                      } catch (error) {

                      }
                    } else if (e?.currency === 'USDT') {
                      try {
                        getPrice = coins?.USDT.find((data) =>
                          (data?.pair).includes(e?.coin)
                        )
                      }
                      catch (e) {

                      }
                    }
                    // const currentPrice = getPrice?.price
                    //   ? parseFloat(getPrice?.price).toFixed(
                    //     coindcxDecimalPair?.base_currency_precision
                    //       ? coindcxDecimalPair?.base_currency_precision
                    //       : 2
                    //   )
                    //   : 0;
                    const getNetProfit =
                      ((e?.currentPrice - e?.ePrice) / e?.ePrice) * 100
                    const placeNetProfit = getNetProfit
                      ? getNetProfit.toFixed(2)
                      : 0
                    return (
                      <Grid item xs={12} md={6} xl={4} key={index}>
                        <Box
                          sx={{
                            bgcolor: '#3E3E3E',
                            //   height: 100,
                            width: '100%',
                            overflow: 'auto',
                            borderRadius: 2,
                            p: 2,
                            display: 'flex',
                            gap: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: { xs: 500, sm: '100%' },
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                width: { xs: 500, sm: '100%' },
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (e?.currency === 'USDT') {
                                    navigate(
                                      '/spot-usdt/' + e?.coin + '-' + e?.currency
                                    )
                                  }
                                  else {
                                    navigate(
                                      '/spot/' + e?.coin + '_' + e?.currency
                                    )
                                  }
                                }
                                }
                              >
                                <img
                                  alt='icon'
                                  src={getPrice?.fromCurrency?.image}
                                  className='img-fluid me-1'
                                  width={25}
                                />
                                {e?.coin}
                                {e?.currency}
                              </Typography>
                              <Box
                                sx={{
                                  color:
                                    placeNetProfit > 0 ? '#21CA50' : '#ff4b5e',
                                  display: 'flex',
                                  gap: 1,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                  pl: 8,
                                }}
                              >
                                <Typography>{placeNetProfit}%</Typography>
                                {placeNetProfit > 0 ? (
                                  <NorthIcon />
                                ) : (
                                  <SouthIcon />
                                )}
                              </Box>
                              <Typography
                                sx={{
                                  color: '#c9c5c7',
                                }}
                              >
                                {formatDate(e?.createdAt)}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 1,
                              }}
                            >
                              <Typography sx={{ fontSize: '0.9rem' }}>
                                Current{' '}
                                {decimalValue(
                                  e?.currentPrice,
                                  coindcxDecimalPair?.base_currency_precision
                                    ? coindcxDecimalPair?.base_currency_precision
                                    : 0
                                )}
                              </Typography>
                              <Typography
                                className='paddingBox'
                                sx={{ bgcolor: '#21CA50', fontSize: '0.9rem' }}
                              >
                                Entry: {e?.ePrice}
                              </Typography>
                              <Typography
                                className='paddingBox'
                                sx={{
                                  bgcolor: '#FF364C',
                                  fontSize: '0.9rem',
                                }}
                              >
                                SL: {e?.sl?.slP}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                bgcolor: ' #5B5B5B',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2,
                                paddingX: 0,
                              }}
                            >
                              <Typography sx={{ width: '100%' }}>
                                <TargetMenu
                                  item={e}
                                  percentageFunc={percentageFunc}
                                />
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )
                  })}
              </Grid>
            </Box>
          ) : value === 1 ? (
            //Featurs Tab
            <Box sx={{ marginY: 4 }}>
              {data2?.length <= 0 && (
                <h4 className='text-center'>No Signals Yet....!!!!!</h4>
              )}
              <Grid container spacing={2}>
                {data2 &&
                  data2.map((e, index) => {
                    // const currentPrice = getPrice?.price
                    //   ? parseFloat(getPrice?.price).toFixed(
                    //     coindcxDecimalPair?.base_currency_precision
                    //       ? coindcxDecimalPair?.base_currency_precision
                    //       : 2
                    //   )
                    //   : 0;
                    let getNetProfit =
                      ((e?.currentPrice - e?.ePrice) / e?.ePrice) * 100

                    if (e?.side == 'Short/Sell') {
                      getNetProfit = getNetProfit * -1;
                    }

                    const placeNetProfit = getNetProfit
                      ? getNetProfit.toFixed(2)
                      : 0
                    return (
                      <Grid item xs={12} md={6} xl={4} key={index}>
                        <Box
                          sx={{
                            bgcolor: '#3E3E3E',
                            //   height: 100,
                            width: '100%',
                            overflow: 'auto',
                            borderRadius: 2,
                            p: 2,
                            display: 'flex',
                            gap: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: { xs: 500, sm: '100%' },
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                width: { xs: 500, sm: '100%' },
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                sx={{ cursor: 'pointer' }}
                                onClick={() =>
                                  navigate(
                                    '/futures/trade/' + e?.symbol
                                  )
                                }
                              >
                                {/* <img
                                alt='icon'
                                src={''}
                                className='img-fluid me-1'
                                width={25}
                              /> */}

                                {e?.coin}
                                {e?.currency} <span style={{ color: '#21CA50' }}>{e?.leverage}x</span> <span style={{ fontSize: '14px', color: e?.side === 'Long/Buy' ? '#21CA50' : '#ff4b5e' }}>{e?.side === 'Long/Buy' ? 'Long' : 'Short'}</span>
                              </Typography>
                              <Box
                                sx={{
                                  color:
                                    placeNetProfit > 0 ? '#21CA50' : '#ff4b5e',
                                  display: 'flex',
                                  gap: 1,
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                  // pl: 8,
                                }}
                              >
                                <Typography>{placeNetProfit}%</Typography>
                                {placeNetProfit > 0 ? (
                                  <NorthIcon />
                                ) : (
                                  <SouthIcon />
                                )}
                              </Box>
                              <Typography
                                sx={{
                                  color: '#c9c5c7',
                                }}
                              >
                                {formatDate(e?.createdAt)}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 1,
                              }}
                            >
                              <Typography sx={{ fontSize: '0.9rem' }}>
                                Current{' '}
                                {e?.currentPrice}
                              </Typography>
                              <Typography
                                className='paddingBox'
                                sx={{ bgcolor: '#21CA50', fontSize: '0.9rem' }}
                              >
                                Entry: {e?.ePrice}
                              </Typography>
                              <Typography
                                className='paddingBox'
                                sx={{
                                  bgcolor: '#FF364C',
                                  fontSize: '0.9rem',
                                }}
                              >
                                SL: {e?.sl?.slP}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                bgcolor: ' #5B5B5B',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2,
                                paddingX: 0,
                              }}
                            >
                              <Typography sx={{ width: '100%' }}>
                                <TargetMenu
                                  item={e}
                                  percentageFunc={e?.side === 'Long/Buy' ? percentageFuturesLongFunc : percentageFuturesShortFunc}
                                />
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )
                  })}
              </Grid>
            </Box>
          ) : (
            <Box sx={{ marginBottom: 4, overflow: 'auto' }}>
              {closedData?.length <= 0 && (
                <h4 className='text-center mt-3'>No Results Yet....!!!!!</h4>
              )}
              <Box
                sx={{
                  overflow: 'auto',
                  minWidth: 1000,
                  display: closedData?.length <= 0 ? 'none' : '',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    fontSize: '38px',
                    textAlign: 'center',
                    marginY: 4,
                    width: '100%',
                  }}
                >
                  <Typography className='w-100'>Coin Name</Typography>
                  <Typography className='w-100'>Trade</Typography>
                  <Typography className='w-100'>Entry</Typography>
                  <Typography className='w-100'>Target 1</Typography>
                  <Typography className='w-100'>Target 2</Typography>
                  <Typography className='w-100'>Target 3</Typography>
                  <Typography className='w-100'>Stop Loss</Typography>
                  <Typography className='w-100'>PnL</Typography>
                </Box>
                {closedData &&
                  closedData.map((e, i) => {
                    let slPer;

                    let percentageFuncV2;
                    if (e?.side === 'Long/Buy') {
                      percentageFuncV2 = percentageFuturesLongFunc;
                      slPer = percentageFuturesLongFunc(e?.sl?.slP, e?.ePrice, e?.leverage)
                    }
                    else if (e?.side === 'Short/Sell') {
                      percentageFuncV2 = percentageFuturesShortFunc;
                      slPer = percentageFuturesShortFunc(e?.sl?.slP, e?.ePrice, e?.leverage)
                    }
                    else {
                      percentageFuncV2 = percentageFunc;
                      slPer = (
                        (e?.sl?.slP / e?.ePrice) * 100 -
                        100
                      ).toFixed(2)
                    }
                    return (
                      <Box
                        sx={{
                          // bgcolor: "#5B5B5B",
                          borderBottom: '1px solid #a0a0a0',
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                          marginY: 1,
                          textAlign: 'center',
                          width: '100%',
                        }}
                        key={i}
                      >
                        <Box className='w-100'>
                          <Typography
                            sx={{
                              color: '#FFF',
                              fontWeight: 'bold',
                              fontSize: '14px',
                            }}
                          >
                            {e?.coin}
                            {e?.currency}{e?.side === 'Long/Buy' ? '/Long' : e?.side === 'Short/Sell' ? '/Short' : null}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#cacaca',
                              fontSize: '10px',
                              fontWeight: 'bold',
                            }}
                          >
                            {formatDate(e?.createdAt)}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            color: '#FFF',
                            fontWeight: 'bold',
                            fontSize: '14px',
                          }}
                          className='w-100'
                        >
                          {e?.symbol ? 'Futures' : 'Spot'}{e?.leverage ? `/${e?.leverage}x` : null}
                        </Typography>
                        <Typography
                          sx={{
                            color: '#FFF',
                            fontWeight: 'bold',
                            fontSize: '14px',
                          }}
                          className='w-100'
                        >
                          {e?.ePrice}
                        </Typography>
                        <Typography
                          sx={{
                            color: '#FFF',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          className='w-100'
                        >
                          <span
                            style={{
                              color: e?.t1?.t1Fill ? '#21CA50' : 'white',
                            }}
                          >
                            {e?.t1?.t1P} (
                            {percentageFuncV2(e?.t1?.t1P, e?.ePrice, e?.leverage)}%)
                          </span>
                          <Box
                            sx={{
                              display: e?.t1?.t1Fill ? 'flex' : 'none',
                              alignItems: 'center',
                              bgcolor: '#21CA50',
                              borderRadius: '50%',
                              padding: '1px',
                              ml: '2px',
                              border: '1px solid #000',
                            }}
                          >
                            <DoneIcon
                              sx={{
                                fontSize: '10px',
                                color: '#000',
                              }}
                            />
                          </Box>
                        </Typography>
                        <Typography
                          sx={{
                            color: '#FFF',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          className='w-100'
                        >
                          <span
                            style={{
                              color: e?.t2?.t2Fill ? '#21CA50' : 'white',
                            }}
                          >
                            {e?.t2?.t2P} (
                            {percentageFuncV2(e?.t2?.t2P, e?.ePrice, e?.leverage)}%)
                          </span>
                          <Box
                            sx={{
                              display: e?.t2?.t2Fill ? 'flex' : 'none',
                              alignItems: 'center',
                              bgcolor: '#21CA50',
                              borderRadius: '50%',
                              padding: '1px',
                              ml: '2px',
                              border: '1px solid #000',
                            }}
                          >
                            <DoneIcon
                              sx={{
                                fontSize: '10px',
                                color: '#000',
                              }}
                            />
                          </Box>
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          className='w-100'
                        >
                          <span
                            style={{
                              color: e?.t3?.t3Fill ? '#21CA50' : 'white',
                            }}
                          >
                            {e?.t3?.t3P} (
                            {percentageFuncV2(e?.t3?.t3P, e?.ePrice, e?.leverage)}%)
                          </span>
                          <Box
                            sx={{
                              display: e?.t3?.t3Fill ? 'flex' : 'none',
                              alignItems: 'center',
                              bgcolor: '#21CA50',
                              borderRadius: '50%',
                              padding: '1px',
                              ml: '2px',
                              border: '1px solid #000',
                            }}
                          >
                            <DoneIcon
                              sx={{
                                fontSize: '10px',
                                color: '#000',
                              }}
                            />
                          </Box>
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          className='w-100'
                        >
                          <span
                            style={{
                              color:
                                e?.sl?.slFill || slPer < 0
                                  ? '#ff4b5e'
                                  : 'white',
                            }}
                          >
                            {e?.sl?.slP} ({slPer}%)
                          </span>
                          <Box
                            sx={{
                              display: e?.sl?.slFill ? 'flex' : 'none',
                              alignItems: 'center',
                              bgcolor: '#ff4b5e',
                              borderRadius: '50%',
                              padding: '1px',
                              ml: '2px',
                              border: '1px solid #000',
                            }}
                          >
                            <DoneIcon
                              sx={{
                                fontSize: '10px',
                                color: '#000',
                              }}
                            />
                          </Box>
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                          }}
                          className='w-100'
                        >
                          <Box
                            sx={{
                              color: e?.netProfit > 0 ? '#21CA50' : '#ff4b5e',
                              display: 'flex',
                              gap: 1,
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              width: '100%',
                            }}
                          >
                            <Typography sx={{ fontWeight: 'bold' }}>
                              {e?.netProfit ? (e?.netProfit).toFixed(2) : 0.0}%
                            </Typography>
                            {e?.netProfit > 0 ? (
                              <NorthIcon sx={{ fontWeight: 'bold' }} />
                            ) : (
                              <SouthIcon sx={{ fontWeight: 'bold' }} />
                            )}
                          </Box>
                        </Typography>
                      </Box>
                    )
                  })}
              </Box>
            </Box>
          )}
        </Box>
        <Box className='position-absolute w-100 bottom-0 start-50 translate-middle-x'>
          <Typography
            sx={{
              color: '#fff',
              fontSize: { xs: '5px', md: '10px' },
              textAlign: 'center',
              p: 1,
              width: '100%',
            }}
            onClick={() => window.open('https://utistaking.com/', '_blank', 'noopener,noreferrer')}
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: { xs: '5px', md: '10px' } }}
              component='span'
            >
              Disclaimer:{' '}
            </Typography>
            Crypto trading involves substantial risk and may result in the loss
            of your capital. Unitic Exchange provides signals for informational
            purposes only and is not responsible for any trading decisions made
            by users. Please trade responsibly.
          </Typography>
        </Box>
      </div>
    </div>
  )
}

export default Signals
