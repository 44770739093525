import React, { Suspense } from 'react';
import '../../../../assets/style.css';
import LoaderUnitic from '../../../../Component/Loader/LoaderUnitic';
const TVChartContainerUsdt = React.lazy(() => import("./TVChartContainerUsdt"));
const TradingViewSpot = (props) => {
  return (
    <Suspense fallback={<LoaderUnitic />}>
    <TVChartContainerUsdt {...props} />
    </Suspense>

  );
}
export default TradingViewSpot;