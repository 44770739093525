import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';

function createData(tier, position, maxL, maintenanceRate, maintenanceAmount) {
    return { tier, position, maxL, maintenanceRate, maintenanceAmount };
}

const rows = [
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
    createData('Tire 1', '0-50,000', '125X', '0.40%', '0'),
];
const commonStyle = { backgroundColor: 'transparent', color: '#FFF', fontFamily: 'Roboto', fontSize: '0.75rem', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal',paddingBottom: '10px',boxShadow: 'none'
}
const styleHead = { background: '#282525', color: '#FFF', fontFamily: 'Roboto', fontSize: '0.75rem', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal',border:0,paddingBottom: '10px'
}
const StyledTableCellHead = styled(TableCell)(({ theme }) => (styleHead));
const StyledTableCellBody = styled(TableCell)(({ theme }) => (commonStyle));

const LeverageMarginUsdt = () => {
    return (
        <div className='pt-2'>
            <TableContainer component={Paper} sx={{
                maxHeight: 475, '&::-webkit-scrollbar': {
                    display: 'none'  /* WebKit (Chrome, Safari, etc.) */
                },
                width: '100%', overflow: 'auto',
                '&.MuiTableContainer-root': commonStyle
            }}>
                <Table size="small" stickyHeader aria-label="a dense table" sx={{ minWidth: 800 }}>
                    <TableHead>
                        <TableRow sx={{ '& td, & th': { border: 0 } }}>
                            <StyledTableCellHead></StyledTableCellHead>
                            <StyledTableCellHead align="center">Position Bracket (Notional Value in USDT)</StyledTableCellHead>
                            <StyledTableCellHead align="center">Max Leverage</StyledTableCellHead>
                            <StyledTableCellHead align="center">Maintenance Margin Rate</StyledTableCellHead>
                            <StyledTableCellHead align="center">Maintenance Amount USDT</StyledTableCellHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '& td, & th': { border: 0 } }}
                            >
                                <StyledTableCellBody align="left">{row?.tier}</StyledTableCellBody>
                                <StyledTableCellBody align="center">{row?.position}</StyledTableCellBody>
                                <StyledTableCellBody align="center">{row?.maxL}</StyledTableCellBody>
                                <StyledTableCellBody align="center">{row?.maintenanceRate}</StyledTableCellBody>
                                <StyledTableCellBody align="center">{row?.maintenanceAmount}</StyledTableCellBody>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default LeverageMarginUsdt;