import React from 'react';
import './MarketPage.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UTI from './UTI';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const MarketPage = () => {
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [marketCurrency, setMarketCurrency] = useState("INR");
    const [search, setSearch] = useState('');
    const [foc, setFoc] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(() => newValue);
        setMarketCurrency(() => event.target?.firstChild?.data);
    };
    return (
        <section className='text-light'>
            <div className='container pb-5 pt-4'>
                <div className='d-flex justify-content-between align-items-start align-items-sm-center flex-column flex-sm-row'>
                    <Box><h1 style={{ fontSize: '1.5rem' }}><strong><ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} /> Market</strong></h1></Box>
                    <Box sx={{
                        textAlign: { xs: 'center', sm: 'left' },
                        fontWeight: 'bold',
                        mt: { xs: 1.5, sm: 0 },
                    }} className='d-flex justify-content-start align-items-center'>

                        <input type="text" className={`${foc ? 'text-light' : 'text-gray'} searchGlassFutures`} style={{ borderRadius: '30px 0 0px 30px', paddingLeft: '12px' }} placeholder="Search" value={search} onChange={(e) => {
                            setSearch(() => e.target.value)
                        }} onKeyDown={(e) => {
                            if (e.key === 'Enter') {

                            }
                        }} onFocus={() => { setFoc(() => true); }} onBlur={() => {
                            setTimeout(() => {
                                setFoc(() => false);
                            }, 100);
                        }} />
                        <button className='searchBtnFutures' style={{ borderRadius: '0 30px 30px 0', paddingLeft: '0px', paddingRight: '12px' }} onClick={() => { }}><SearchIcon fontSize='sm' /></button>

                    </Box>
                </div>

                <div className='mb-3 mt-1'>
                    <Box sx={{
                        width: '100%'
                    }}>
                        <Box >
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                                sx={{
                                    '& .MuiTabs-indicator': {
                                        background: 'linear-gradient(90.03deg, #10EDB6 0.02%, #4C62F7 100%);',
                                        borderRadius: "17px"
                                    },
                                    '& .MuiTab-root': {
                                        color: 'white',
                                        textTransform: "capitalize",
                                        fontSize: "18px",
                                        fontWeight: "600",
                                        lineHeight: "38px",
                                        fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                                        fontStyle: "normal",
                                        padding: "auto"
                                    },
                                    '& .MuiTab-root.Mui-selected': {
                                        color: 'white',
                                        // fontWeight: "800"
                                    }

                                }}
                            >
                                <Tab label="INR" {...a11yProps(0)} />
                                {/* <Tab label="UTI" {...a11yProps(1)} /> */}
                                <Tab label="USDT" {...a11yProps(2)} />
                                {/* <Tab label="BTC" {...a11yProps(3)} /> */}
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <UTI marketCurrency={marketCurrency} search={search} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <UTI marketCurrency={marketCurrency} search={search} isKucoin={true}/>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <UTI marketCurrency={marketCurrency} search={search} />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <UTI marketCurrency={marketCurrency} search={search} />
                        </TabPanel>
                    </Box>
                </div>
            </div>
        </section>
    );
};

export default MarketPage;