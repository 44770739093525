import React from "react";
import '../../../assets/style.css';
import LoaderSpin from "../../../Component/Loader/LoaderSpin";

const SpotRecentUsdt = (props) => {
    const {
        tradeHistory = [],
        pairsLoader
    } = props;
    function setTime(timeValue) {
        const milliseconds = timeValue / 1000000;
        const date = new Date(milliseconds);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        const formattedTime = `${hours}:${minutes}:${seconds}`;
        return formattedTime;
    }
    return (
        <div className='trading-page-top-section-3 '>
            {/* <div className="d-flex flex-row trading-page-top-section-2 align-items-center ">
                <div className="ps-2">
                    <p className="trade-text-5 mb-0 pt-2">Real-Time Trades</p>
                </div>
            </div> */}
            <div className='trading-table-left-recent-trade progress-bar-bg-color '>
                <div className="d-flex flex-row mb-1 mobile-justify-content orderbook-table-heading-section">
                    <div className="progress-table-1"><span className='orderbook-text-1'>Price(USDT)</span></div>
                    <div className="progress-table-2"><span className='orderbook-text-1'>Amount</span></div>
                    <div className="progress-table-3"><span className='orderbook-text-1'>Time</span></div>
                </div>
                {
                    pairsLoader ? <div className='futures-trading-table-left-recent-trade-height progress-bar-bg-color ' style={{ height: '370px' }}><LoaderSpin /></div> :
                        <div className='futures-trading-table-left-recent-trade-height progress-bar-bg-color '>
                            {tradeHistory && tradeHistory.length > 0 && tradeHistory?.slice(0, 18)?.map((tradeHistory, i) => {
                                const clrClass = tradeHistory.side === 'sell' ? "color-red-futures" : "color-green-futures";
                                return (<div key={i} className="d-flex flex-row mobile-justify-content list-hover-tooltip">
                                    <div className={"progress-table-1 " + (clrClass)}><span className='orderbook-text-4'>
                                        {(props.priceObj?.changePer * (+tradeHistory?.price)).toFixed(props.priceObj?.priceDecimal)}</span></div>
                                    <div className="progress-table-2 text-end pe-2"><span className='orderbook-text-4' style={{ color: '#C8C8C8' }}>{props.formatWithK(tradeHistory?.size, props.priceObj?.baseDecimal)}</span></div>
                                    <div className="progress-table-3"><span className='orderbook-text-4' style={{ color: '#C8C8C8' }}>{setTime(tradeHistory?.time)}</span></div>
                                </div>)
                            })}
                        </div>
                }
            </div>
        </div>
    );
}

export default SpotRecentUsdt;