import React, { useEffect, useState } from "react";
// import { TfiReload } from 'react-icons/tfi';
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormatOKX, showPairNameUsdt } from '../../../core/helper/date-format';
import { Link, useNavigate } from "react-router-dom";
import Pagination from 'react-responsive-pagination';
import '../../../pagination.css';
import '../../../assets/style.css';
import '../../../assets/styledev.css';
import AddTpSl from "./dialogsUsdt/AddTpSlUsdt";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AlertDialog from "./dialogsUsdt/CloseConfirmationUsdt";
import LoaderSpin from "../../../Component/Loader/LoaderSpin";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'transparent', color: 'rgb(243, 243, 243)', width: '100%'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  padding: "10px 4px"
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    background: 'transparent', color: 'rgb(243, 243, 243)'
  },
  background: 'transparent', color: 'rgb(243, 243, 243)',
  // hide last border
  'td,th': {
    border: 0,
  },
}));

export default function SpotMyOrdersUsdt(props) {
  const {
    myProfile = {},
    trades = {
      openOrders: [], stopOrders: [], orders: [], fills: []
    },
    symbolsData = [],
    countDecimals,
    cancelOrder,
    pairsLoader,
    orderFetchLoading,
    openOrdersDataFetch,
    currentPage,
    totalPages,
    orderHistoryDataFetch,
    stopOrderDataFetch,
    fillsOrderDataFetch
  } = props;
  const navigate = useNavigate();
  const [userHistoryTab, setUserHistoryTab] = useState("Open Orders");
  useEffect(() => {
    $(".status_change .dropdown-item").click(function () {
      var getStatusText = $(this).text();
      $(this).closest(".status_dropdown").find(".status__btn").text(getStatusText);
      var generateStatusClass = `${$(this).attr('data-class')}-status`
      $(this).closest(".status_dropdown").attr("data-color", `${generateStatusClass}`);
    })
  }, []);
  // const copyText = (data = {}) => {
  //   if (data.text) {
  //     var input = document.createElement("input");
  //     document.body.appendChild(input);
  //     input.value = data.text;
  //     input.select();
  //     document.execCommand("Copy");
  //     input.remove();
  //     toast({ type: "success", message: data.message });
  //   }
  // }
  return (
    <div>

      <div className='d-flex flex-row trading-page-order-details-section '>
        <div className='col-12'>
          <div className="d-flex justify-content-start paired-trade-table-section align-items-center py-3 py-sm-3 py-md-3 py-lg-0">
            <div className="">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("Open Orders")}>
                  <button
                    className="nav-link active"
                    id="pills-openorders-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-openorders"
                    type="button" role="tab"
                    aria-controls="pills-openorders"
                    aria-selected="false"
                  >
                    Open Orders ({trades?.openOrders?.length || 0})
                  </button>
                </li>

                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("Stop Orders")}>
                  <button
                    className="nav-link"
                    id="pills-positionhistory-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-positionhistory"
                    type="button"
                    role="tab"
                    aria-controls="pills-positionhistory"
                    aria-selected="false"
                  >
                    Stop Orders ({trades?.stopOrders?.length || 0})
                  </button>
                </li>
                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("Order History")}>
                  <button
                    className="nav-link"
                    id="pills-orderhistory-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-orderhistory"
                    type="button"
                    role="tab"
                    aria-controls="pills-orderhistory"
                    aria-selected="false"
                  >
                    Order History
                  </button>
                </li>
                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("Trade History")}>
                  <button
                    className="nav-link"
                    id="pills-positionhistory-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-positionhistory"
                    type="button"
                    role="tab"
                    aria-controls="pills-positionhistory"
                    aria-selected="false"
                  >
                    Trade History
                  </button>
                </li>
              </ul>
            </div>

          </div>

          {(!myProfile && !myProfile?._id)
            ?
            <div className='trading-page-order-tabs-section pt-5'>
              <span className='trade-bottom-login-text-1'>
                <span className='fc-g cursor-pointer' onClick={() => navigate('/login')}>Login </span>(or)
                <span className='fc-g cursor-pointer' onClick={() => navigate('/register')}> Register Now</span> to trade
              </span>
            </div>
            : (pairsLoader || orderFetchLoading) ? <div className='trading-page-order-tabs-section pt-3'><LoaderSpin /></div> :
              <>
                <div className="trading-page-order-table-details-section" style={{ height: '30vh', overflow: 'auto', borderRight: '1px solid rgb(238, 240, 242)' }}>
                  {userHistoryTab == "Open Orders" ?
                    <TableContainer>
                      <Table sx={{ minWidth: window.innerWidth < 700 ? 900 : 850, justifyContent: 'center' }} aria-label="customized table" stickyHeader >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Time</StyledTableCell>
                            <StyledTableCell>Pair</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Side</StyledTableCell>
                            <StyledTableCell>Price</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Filled</StyledTableCell>
                            <StyledTableCell>Unfilled</StyledTableCell>
                            <StyledTableCell>Cancel All</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody sx={{
                          '&.MuiTableBody-root': {
                            height: '100%',
                          }
                        }}>
                          {trades?.openOrders?.length > 0 && trades?.openOrders.map((elem, i) => {
                            // if (elem.type !== 'limit' || elem.type !== 'market') return null;
                            const {
                              orderId = null,
                              id = null,
                              symbol = '',
                              type = '',
                              price = '0',
                              size = 0,
                              side = '',
                              orderTime = '0',
                              createdAt = null,
                              orderType = '',
                              filledSize = null, dealSize = null
                            } = elem
                            const clrClassName = side == 'buy' ? 'color-green-futures' : 'color-red-futures';

                            return <StyledTableRow key={i}>
                              <StyledTableCell>{dateFormatOKX(createdAt || orderTime / 100000)}</StyledTableCell>
                              <StyledTableCell>{symbol ?
                                <Link to={"/spot-usdt/" + symbol} className="text-white">{showPairNameUsdt(symbol)}</Link> : ""
                              }</StyledTableCell>
                              <StyledTableCell className={"capitalizeText "}>{orderType || type}</StyledTableCell>
                              <StyledTableCell className={"capitalizeText " + (clrClassName)}>{side}</StyledTableCell>
                              <StyledTableCell>{price}</StyledTableCell>
                              <StyledTableCell>{size}</StyledTableCell>
                              <StyledTableCell>{filledSize || dealSize || '0'}</StyledTableCell>
                              <StyledTableCell>{Number(size) - Number(filledSize || dealSize) || '0'}</StyledTableCell>
                              <StyledTableCell><div className="d-flex justify-content-start"><button type="button" className="market-trade-button px-1" onClick={async () => await cancelOrder(id || orderId, true)}>Cancel</button></div></StyledTableCell>
                            </StyledTableRow>
                          })}
                          {trades?.openOrders?.length == 0 &&
                            <StyledTableRow>
                              <StyledTableCell className='text-white'>No Active Orders Found!</StyledTableCell>
                            </StyledTableRow>
                          }
                          {trades?.openOrders?.length > 0 && <StyledTableRow>
                            <Pagination className="pagination"
                              total={totalPages?.totalOpenOrders}
                              current={currentPage?.openOrders}
                              onPageChange={page => openOrdersDataFetch(page)}
                            />
                          </StyledTableRow>}
                        </TableBody>
                      </Table>
                    </TableContainer> : null
                  }
                  {userHistoryTab == "Order History" ?
                    <TableContainer>
                      <Table sx={{ minWidth: window.innerWidth < 700 ? 900 : 1000, justifyContent: 'center' }} aria-label="customized table" stickyHeader size='small'>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Time</StyledTableCell>
                            <StyledTableCell>Pair</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Side</StyledTableCell>
                            <StyledTableCell>Trigger Condition</StyledTableCell>
                            <StyledTableCell>Price</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Filled</StyledTableCell>
                            <StyledTableCell>Unfilled</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            {/* <StyledTableCell>Action</StyledTableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody sx={{
                          '&.MuiTableBody-root': {
                            height: '100%',
                          }
                        }}>
                          {trades?.orders?.length > 0 && trades?.orders.map((elem, i) => {
                            if (elem?.status === 'active' || elem?.status === 'open' || elem?.isActive === 'true' || elem?.isActive === true) { return null }

                            const {
                              side = '',
                              symbol = '',
                              createdAt = null,
                              size = 0,
                              price = '',
                              type = '',
                              isActive = null,
                              cancelExist = null,
                              dealSize = null,
                              filledSize = null,
                              stopTriggered = null, ts = '', orderType = '', canceledSize = null, orderPrice = ''
                            } = elem;

                            const clrClassName = side === 'buy' ? 'color-green-futures' : 'color-red-futures';
                            return <StyledTableRow key={i}>
                              <StyledTableCell>{dateFormatOKX(createdAt || ts / 1000000)}</StyledTableCell>
                              <StyledTableCell>{symbol ?
                                <Link to={"/spot-usdt/" + symbol} className="text-white">{showPairNameUsdt(symbol)}</Link> : ""
                              }</StyledTableCell>
                              <StyledTableCell className={"capitalizeText "}>{orderType || type}</StyledTableCell>
                              <StyledTableCell className={"capitalizeText " + (clrClassName)}>{side}</StyledTableCell>
                              <StyledTableCell className={"capitalizeText "}>{stopTriggered ? 'Triggered' : 'Not Triggered'}</StyledTableCell>
                              <StyledTableCell>{price || orderPrice}</StyledTableCell>
                              <StyledTableCell>{size}</StyledTableCell>
                              <StyledTableCell>{filledSize || dealSize || '0'}</StyledTableCell>
                              <StyledTableCell>{Number(size) - Number(filledSize || dealSize) || '0'}</StyledTableCell>
                              <StyledTableCell data-label="Status" className="capitalizeText">{(!isActive && (cancelExist || +(canceledSize) > 0)) ? 'cancelled' : (type.toLowerCase() === 'triggered' || type.toLowerCase() === 'cancel') ? type.toLowerCase() === 'cancel' ? 'cancelled' : type : 'filled'}</StyledTableCell>
                            </StyledTableRow>
                          })}
                          {trades?.orders?.length == 0 &&
                            <StyledTableRow>
                              <StyledTableCell className='text-white'>No History Found!</StyledTableCell>
                            </StyledTableRow>
                          }
                          {trades?.orders?.length > 0 && <StyledTableRow>
                            <Pagination className="pagination"
                              total={totalPages?.totalOrders}
                              current={currentPage?.orders}
                              onPageChange={page => orderHistoryDataFetch(page)}
                            />
                          </StyledTableRow>}
                        </TableBody>
                      </Table>
                    </TableContainer> : null
                  }
                  {userHistoryTab == "Stop Orders" ?
                    <TableContainer>
                      <Table sx={{ minWidth: window.innerWidth < 700 ? 900 : 1000, justifyContent: 'center' }} aria-label="customized table" stickyHeader size='small'>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Time</StyledTableCell>
                            <StyledTableCell>Pair</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Side</StyledTableCell>
                            <StyledTableCell>Trigger Condition</StyledTableCell>
                            <StyledTableCell>Stop Price</StyledTableCell>
                            <StyledTableCell>Price</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Cancel All</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody sx={{
                          '&.MuiTableBody-root': {
                            height: '100%',
                          }
                        }}>
                          {trades?.stopOrders?.length > 0 && trades?.stopOrders.map((elem, i) => {
                            // if (elem.type !== 'limit_stop' || elem.type !== 'market_stop') return null;
                            const { orderId = null, id = null, symbol = '', type = '',stopPrice='0', price = '0', size = 0, side = '', createdAt = null,
                              orderTime = null, stopTriggered = null, ts = '', orderType = '', orderPrice = ''
                            } = elem;
                            const clrClassName = side == 'buy' ? 'color-green-futures' : 'color-red-futures';
                            const symbolDetails = symbolsData[symbol]
                            const priceDecimal = countDecimals(symbolDetails.priceIncrement)
                            const baseDecimal = countDecimals(symbolDetails.baseIncrement)
                            return <StyledTableRow key={i}>
                              <StyledTableCell>{dateFormatOKX(createdAt || orderTime / 1000000 || ts / 1000000)}</StyledTableCell>
                              <StyledTableCell>{symbol ?
                                <Link to={"/spot-usdt/" + symbol} className="text-white">{showPairNameUsdt(symbol)}</Link> : ""
                              }</StyledTableCell>
                              <StyledTableCell className={"capitalizeText "}>{orderType || type}</StyledTableCell>
                              <StyledTableCell className={"capitalizeText " + (clrClassName)}>{side}</StyledTableCell>
                              <StyledTableCell className={"capitalizeText "}>{stopTriggered ? 'Triggered' : 'Not Triggered'}</StyledTableCell>
                              <StyledTableCell>{(+stopPrice).toFixed(priceDecimal || 2)}</StyledTableCell>
                              <StyledTableCell>{(+price || +orderPrice).toFixed(priceDecimal || 2)}</StyledTableCell>
                              <StyledTableCell>{(+size).toFixed(baseDecimal || 2)}</StyledTableCell>
                              <StyledTableCell><div className="d-flex justify-content-start"><button type="button" className="market-trade-button px-1" onClick={async () => await cancelOrder(id || orderId, true, 'stop')}>Cancel</button></div></StyledTableCell>
                            </StyledTableRow>
                          })}
                          {trades?.stopOrders?.length == 0 &&
                            <StyledTableRow>
                              <StyledTableCell className='text-white'>No Stop Orders Found!</StyledTableCell>
                            </StyledTableRow>
                          }
                          {trades?.stopOrders?.length > 0 && <StyledTableRow>
                            <Pagination className="pagination"
                              total={totalPages?.totalStopOrders}
                              current={currentPage?.stopOrders}
                              onPageChange={page => stopOrderDataFetch(page)}
                            />
                          </StyledTableRow>}
                        </TableBody>
                      </Table>
                    </TableContainer> : null
                  }
                  {userHistoryTab == "Trade History" ?
                    <TableContainer>
                      <Table sx={{ minWidth: window.innerWidth < 700 ? 900 : 1000, justifyContent: 'center' }} aria-label="customized table" stickyHeader size='small'>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Time Filled</StyledTableCell>
                            <StyledTableCell>Pair</StyledTableCell>
                            <StyledTableCell>Type</StyledTableCell>
                            <StyledTableCell>Side</StyledTableCell>
                            <StyledTableCell>Fill Price</StyledTableCell>
                            <StyledTableCell>Amount</StyledTableCell>
                            <StyledTableCell>Volume</StyledTableCell>
                            <StyledTableCell>Fee</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody sx={{
                          '&.MuiTableBody-root': {
                            height: '100%',
                          }
                        }}>
                          {trades?.fills?.length > 0 && trades?.fills.map((elem, i) => {
                            // if (elem?.status !== 'done') { return null }

                            const {
                              side = '',
                              symbol = '',
                              size = 0,
                              price = '',
                              type = '',
                              fee = '0',
                              createdAt = '', ts = '', feeCurrency = '', orderType = ''
                            } = elem;

                            const clrClassName = side === 'buy' ? 'color-green-futures' : 'color-red-futures';
                            return <StyledTableRow key={i}>
                              <StyledTableCell>{dateFormatOKX(createdAt || ts / 100000)}</StyledTableCell>
                              <StyledTableCell>{symbol ?
                                <Link to={"/spot-usdt/" + symbol} className="text-white">{showPairNameUsdt(symbol)}</Link> : ""
                              }</StyledTableCell>
                              <StyledTableCell className={"capitalizeText "}>{orderType || type}</StyledTableCell>
                              <StyledTableCell className={"capitalizeText " + (clrClassName)}>{side}</StyledTableCell>
                              <StyledTableCell>{price}</StyledTableCell>
                              <StyledTableCell>{size}</StyledTableCell>
                              <StyledTableCell>{'--'}</StyledTableCell>
                              <StyledTableCell>{fee + ' '}{feeCurrency}</StyledTableCell>
                            </StyledTableRow>
                          })}
                          {trades?.fills?.length == 0 &&
                            <StyledTableRow>
                              <StyledTableCell className='text-white'>No Fills Found!</StyledTableCell>
                            </StyledTableRow>
                          }
                          {trades?.fills?.length > 0 && <StyledTableRow>
                            <Pagination className="pagination"
                              total={totalPages?.totalFills}
                              current={currentPage?.fills}
                              onPageChange={page => fillsOrderDataFetch(page)}
                            />
                          </StyledTableRow>}
                        </TableBody>
                      </Table>
                    </TableContainer> : null
                  }
                </div>
              </>

          }
        </div>
      </div>

    </div>
  );
}
