import React from 'react';
import './Banner.css';
import { Box, createTheme, ThemeProvider, Typography } from '@mui/material';
import { useContextData } from '../../core/context';
const Banner = () => {
  const [selectedDays, setSelectedDays] = React.useState('1W')
  const { spotPnl } = useContextData()
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 500,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  return (
    <div className='banner-container mt-3'>
      <div>
        <marquee> <p className='fw-bold'>Unitic Exchange Indian Rupee Deposit Is Temporarily Unavailable. Do Not Deposit Till Further Notice. Happy Trading..!!</p> </marquee>
        <div className='banner-main'>
          {/* <img src="/assets/banner/banner.webp" alt="" className='img-fluid' style={{ width: 'calc(100vh - 200px)' }} /> */}
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                p: { xs: 1, md: 2 },
                borderRadius: { xs: '26px', md: '32px' },
                border: '1px solid rgba(0, 0, 0, 0.00)',
                background: 'linear-gradient(273deg, #E8FFEE 2.37%, #FFF 96.14%)',
                width: { xs: '170px', sm: '45%' },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: '#000',
                  fontSize: { xs: 15, md: 18, lg:20 },
                  textAlign: 'left'
                }}
              >
                Signals
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  bgcolor: '#2E2D2D',
                  borderRadius: '50px',
                  width: '100%',
                  textAlign: 'center',
                  color: '#fff',
                  marginTop: 1,
                }}
              >
                {['1W', '1M', '3M', '6M', '1Y'].map((e) => {
                  return (
                    <Typography
                      sx={{
                        bgcolor: selectedDays === e && '#21CA50',
                        padding: {
                          xs: '2px 5px',
                          md: '4px 15px',
                        },
                        fontSize: { xs: 12, md: 14, lg:16 },
                        borderRadius: '50px',
                        cursor: 'pointer',
                      }}
                      onClick={() => setSelectedDays(e)}
                    >
                      {e}
                    </Typography>
                  )
                })}
              </Box>
              <Box>
                {selectedDays === '1W' && (
                  <Box
                    sx={{
                      color: '#000',
                      maxWidth: '100%',
                      marginTop: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          // fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        Trades{' '}
                      </Box>
                      <Box
                        sx={{
                          fontWeight: 'bold',
                          color: '#000',
                          fontSize: { xs: 12, md: 14, lg:16 },
                        }}
                      >
                        {spotPnl?.seven?.total || 0}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          // fontWeight: 'bold',
                          color: '#000',
                          fontSize: { xs: 12, md: 14, lg:16 },
                        }}
                      >
                        Avg Profit{' '}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          fontWeight: 'bold',
                          color: spotPnl?.seven?.avg >= 0 ? '#2FAE73' : '#ff4b5e',
                        }}
                      >
                        {spotPnl?.seven?.avg > 0
                          ? `+${spotPnl?.seven?.avg || 0}`
                          : spotPnl?.seven?.avg || 0}
                        %
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          // fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        Profit & Loss
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          fontWeight: 'bold',
                          color: spotPnl?.seven?.pnl >= 0 ? '#2FAE73' : '#ff4b5e',
                        }}
                      >
                        {spotPnl?.seven?.pnl > 0
                          ? `+${spotPnl?.seven?.pnl || 0}`
                          : spotPnl?.seven?.pnl || 0}
                        %
                      </Typography>
                    </Box>
                  </Box>
                )}
                {selectedDays === '1M' && (
                  <Box
                    sx={{
                      color: '#000',
                      maxWidth: '100%',
                      marginTop: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          // fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        Trades{' '}
                      </Box>
                      <Box
                        sx={{
                          fontWeight: 'bold',
                          color: '#000',
                          fontSize: { xs: 12, md: 14, lg:16 },
                        }}
                      >
                        {spotPnl?.thirty?.total || 0}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          // fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        Avg Profit{' '}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          fontWeight: 'bold',
                          color:
                            spotPnl?.thirty?.avg >= 0 ? '#2FAE73' : '#ff4b5e',
                        }}
                      >
                        {spotPnl?.thirty?.avg > 0
                          ? `+${spotPnl?.thirty?.avg || 0}`
                          : spotPnl?.thirty?.avg || 0}
                        %
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          // fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        Profit & Loss
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          fontWeight: 'bold',
                          color:
                            spotPnl?.thirty?.pnl >= 0 ? '#2FAE73' : '#ff4b5e',
                        }}
                      >
                        {spotPnl?.thirty?.pnl > 0
                          ? `+${spotPnl?.thirty?.pnl || 0}`
                          : spotPnl?.thirty?.pnl || 0}
                        %
                      </Typography>
                    </Box>
                  </Box>
                )}
                {selectedDays === '3M' && (
                  <Box
                    sx={{
                      color: '#000',
                      maxWidth: '100%',
                      marginTop: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          // fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        Trades{' '}
                      </Box>
                      <Box
                        sx={{
                          fontWeight: 'bold',
                          color: '#000',
                          fontSize: { xs: 12, md: 14, lg:16 },
                        }}
                      >
                        {spotPnl?.ninety?.total || 0}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          // fontWeight: 'bold',
                          color: '#000',
                          fontSize: { xs: 12, md: 14, lg:16 },
                        }}
                      >
                        Avg Profit{' '}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          fontWeight: 'bold',
                          color:
                            spotPnl?.ninety?.avg >= 0 ? '#2FAE73' : '#ff4b5e',
                        }}
                      >
                        {spotPnl?.ninety?.avg > 0
                          ? `+${spotPnl?.ninety?.avg || 0}`
                          : spotPnl?.ninety?.avg || 0}
                        %
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          // fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        Profit & Loss
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: { xs: 12, md: 14, lg:16 },
                          color:
                            spotPnl?.ninety?.pnl >= 0 ? '#2FAE73' : '#ff4b5e',
                        }}
                      >
                        {spotPnl?.ninety?.pnl > 0
                          ? `+${spotPnl?.ninety?.pnl || 0}`
                          : spotPnl?.ninety?.pnl || 0}
                        %
                      </Typography>
                    </Box>
                  </Box>
                )}
                {selectedDays === '6M' && (
                  <Box
                    sx={{
                      color: '#000',
                      maxWidth: '100%',
                      marginTop: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          // fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        Trades{' '}
                      </Box>
                      <Box
                        sx={{
                          fontWeight: 'bold',
                          color: '#000',
                          fontSize: { xs: 12, md: 14, lg:16 },
                        }}
                      >
                        {spotPnl?.oneEighty?.total || 0}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          // fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        Avg Profit{' '}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: { xs: 12, md: 14, lg:16 },
                          color:
                            spotPnl?.oneEighty?.avg >= 0 ? '#2FAE73' : '#ff4b5e',
                        }}
                      >
                        {spotPnl?.oneEighty?.avg > 0
                          ? `+${spotPnl?.oneEighty?.avg || 0}`
                          : spotPnl?.oneEighty?.avg || 0}
                        %
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          // fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        Profit & Loss
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: { xs: 12, md: 14, lg:16 },
                          color:
                            spotPnl?.oneEighty?.pnl >= 0 ? '#2FAE73' : '#ff4b5e',
                        }}
                      >
                        {spotPnl?.oneEighty?.pnl > 0
                          ? `+${spotPnl?.oneEighty?.pnl || 0}`
                          : spotPnl?.oneEighty?.pnl || 0}
                        %
                      </Typography>
                    </Box>
                  </Box>
                )}
                {selectedDays === '1Y' && (
                  <Box
                    sx={{
                      color: '#000',
                      maxWidth: '100%',
                      marginTop: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          // fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        Trades{' '}
                      </Box>
                      <Box
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        {spotPnl?.threeHundredSixty?.total || 0}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          // fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        Avg Profit{' '}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: { xs: 12, md: 14, lg:16 },
                          color:
                            spotPnl?.threeHundredSixty?.avg >= 0
                              ? '#2FAE73'
                              : '#ff4b5e',
                        }}
                      >
                        {spotPnl?.threeHundredSixty?.avg > 0
                          ? `+${spotPnl?.threeHundredSixty?.avg || 0}`
                          : spotPnl?.threeHundredSixty?.avg || 0}
                        %
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          // fontWeight: 'bold',
                          color: '#000',
                        }}
                      >
                        Profit & Loss
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14, lg:16 },
                          fontWeight: 'bold',
                          color:
                            spotPnl?.threeHundredSixty?.pnl >= 0
                              ? '#2FAE73'
                              : '#ff4b5e',
                        }}
                      >
                        {spotPnl?.threeHundredSixty?.pnl > 0
                          ? `+${spotPnl?.threeHundredSixty?.pnl || 0}`
                          : spotPnl?.threeHundredSixty?.pnl || 0}
                        %
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </ThemeProvider>
        </div>
        <div className='paddingSpace'>
          <h1 className='bannerHeading d-none d-md-block'>The India's First Exchange Providing<br /><span style={{ color: '#1e5d00' }}>Spot & Futures</span> Signals</h1>
          <h1 className='bannerHeading d-block d-md-none'>The India's First Exchange Providing <span style={{ color: '#1e5d00' }}>Spot & Futures</span> Signals</h1>
          <p className='bannerSubHeading'>We are FIU registered & 100% compliant with Indian regulations.</p>
        </div>
        <div className='btns-handle-banner'>
          <img src="/assets/banner/gplay.webp" width={180} loading='lazy' alt="" className='img-fluid' onClick={() => window.open('https://play.google.com/store/apps/details?id=com.app.unitic&hl=en-IN/', '_blank', 'noopener,noreferrer')} />
          <img src="/assets/banner/appplay.webp" width={180} loading='lazy' alt="" className='img-fluid' />
        </div>
      </div>


    </div>
  );
};

export default Banner;