import React, { useEffect, useState } from 'react';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
const FavStarUsdt = ({ updateFavPairs, symbol, dataSingle, favs }) => {
    const [value, setValue] = useState(0);
    useEffect(() => {
        const isExist = favs ? favs.find(data => data?.symbol === symbol) : false;
        if (isExist && isExist?.symbol) {
            setValue(() => 1)
        }
        else {
            setValue(() => 0)
        }
    }, [symbol, favs])
    return (
        <span className='d-flex justify-content-start align-items-center'>
            <Rating
                name="simple-controlled"
                value={value}
                max={1}
                size="small"
                onChange={async (event, newValue) => {
                    setValue(newValue);
                    await updateFavPairs(symbol, dataSingle)
                }}
                sx={{
                    '&.MuiRating-label': {
                        //   color: 'red'  
                    }
                }}
                emptyIcon={<StarIcon style={{ color: '#404040', fontSize: '1.125rem' }} />}
                icon={<StarIcon style={{ fontSize: '1.125rem' }} />}
            />
        </span>
    );
};

export default FavStarUsdt;