import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        color: '#FFF',
        fontFamily: "Roboto",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        borderTop: '1px solid rgba(200, 200, 200, 0.32)',
        borderBottom: '0px'
    },
    '& .MuiDialogTitle-root': {
        color: '#FFF',
        fontFamily: "Roboto",
        fontSize: '0.975rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
        justifyContent: 'center',
    },
    '& .MuiDialog-paper': {
        borderRadius: '8px',
        background: '#2E2C2C',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        color: '#FFF',
        fontFamily: "Roboto",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    }
}));

export default function ModifyOrderUsdt({ open, handleClickOpen, handleClose }) {

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Modify Order
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <div className='d-flex justify-content-between align-items-center handleModifyFirstProps mb-1' style={{ gap: '5px' }}>
                        <div className='d-flex justify-content-start align-items-center' style={{ gap: '10px' }}>
                            <div><img src='/assets/bitcoin.png' alt='' className='img-fluid' width={30} /></div>
                            <div><p>BTCUSDT Perpetual</p></div>
                        </div>
                        <div><p>isolated</p></div>
                        <div><p>Buy 10.00X</p></div>
                    </div>
                    <div className='py-1'>
                        <div className='d-flex justify-content-between align-items-center handleModifyPropsColor my-3' style={{ gap: '5px' }}>
                            <p>Last price</p>
                            <p>43000</p>
                        </div>
                        <div className='d-flex justify-content-between align-items-center handleModifyPropsColor my-3' style={{ gap: '5px' }}>
                            <p>Max Price</p>
                            <p>43000</p>
                        </div>
                    </div>

                    <div>
                        <Form.Label>Limit Price</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="300"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className='inputOfModify'
                            />
                            <InputGroup.Text id="basic-addon2" className='lastBtn'>43000 USDT</InputGroup.Text>
                        </InputGroup>
                        <Form.Label>Amount</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="200"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className='inputOfModify'
                            />
                            <InputGroup.Text id="basic-addon2" className='lastBtn'>43000 USDT</InputGroup.Text>
                        </InputGroup>
                    </div>
                    <div>
                        <p>Mode</p>
                        <p>OCO  TP/SL</p>
                    </div>
                    <div>

                    </div>
                </DialogContent>
                <DialogActions>
                    <button className='btn-cancel' onClick={handleClose}>
                        Cancel
                    </button>
                    <button className='btn-confirm' onClick={handleClose}>

                        Confirm
                    </button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}