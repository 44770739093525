import React, { useEffect, useState } from 'react';
import {
    Box, Typography,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useContextData } from '../../core/context';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './Portfolio.css'
import SwapVertIcon from '@mui/icons-material/SwapVert';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Spinner } from 'react-bootstrap';
import CustomTableRowUSDT from './CustomTableRowUSDT';
import TransferModalUSDT from './TransferModalUSDT';

const customStyle = {
    // tableLayout:'fixed',
    // minWidth: window.innerWidth < 700 ? 1000 : 100,
    justifyContent: 'center'
}
const WalletListSpotUsdt = (props) => {
    const { balShow, balShowHideCall, fundMoveUSDT, fundUSDTLoading, walletDataUSDT = {}, loadingUsdt = false } = props;
    const { getSpotUsdtBalancePortfolio, spotBalPortfolio, setSpotBalPortfolio } = useContextData();
    const [reState, setRestate] = useState(null);
    const [search, setSearch] = useState('');
    const [foc, setFoc] = useState(false);
    const [sort, setSort] = useState(1);
    useEffect(() => {
        // if (!spotBalPortfolio?.data) {
        getSpotUsdtBalancePortfolio();
        // }
    }, [])
    useEffect(() => {
        setSpotBalPortfolio(el => { return { ...el, loader: true } })
        const matchedKeys = (spotBalPortfolio?.data ? spotBalPortfolio?.data.filter(key => key?.currency.includes(search?.toUpperCase())) : []) || [];
        setRestate(() => {
            let sorted = matchedKeys.sort((a, b) => { return +(b.value) - +(a.value) })
            return sorted;
        });
        setSpotBalPortfolio(el => { return { ...el, loader: false } })
    }, [spotBalPortfolio?.data])
    useEffect(() => {
        setSpotBalPortfolio(el => { return { ...el, loader: true } })
        if (sort == 1) {
            setRestate(el => {
                let sorted = el.sort((a, b) => { return +(b.value) - +(a.value) })
                return sorted;
            })
        }
        else if (sort == 2) {
            setRestate(el => {
                let sorted = el.sort((a, b) => { return +(a.value) - +(b.value) })
                return sorted;
            })
        }
        setSpotBalPortfolio(el => { return { ...el, loader: false } })
    }, [sort])

    const searchFuncUSDT = () => {
        setSpotBalPortfolio(el => { return { ...el, loader: true } })
        const matchedKeys = spotBalPortfolio?.data && spotBalPortfolio?.data.filter(key => key?.currency.includes(search?.toUpperCase()));
        setRestate(() => matchedKeys);
        setSpotBalPortfolio(el => { return { ...el, loader: false } });
    }
    return (
        <div className='mb-5'>

            <Box>
                <Box
                    sx={{
                        bgcolor: '#3d3d3d',
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        p: 2,
                        pl: 3,
                        pr: 3,
                        borderRadius: 5,
                        mt: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                textAlign: { xs: 'center', md: 'left' },
                                fontSize: { xs: '0.85rem', lg: '0.9rem' },
                            }}
                        >
                            <span onClick={() => window.open('https://utistaking.com/', '_blank', 'noopener,noreferrer')}>Estimated</span> Balance {balShow ? <AiFillEye
                                onClick={() => balShowHideCall()}
                                sx={{ color: "white", marginTop: "-5px", marginLeft: "5px", cursor: "pointer" }} /> : <AiFillEyeInvisible
                                onClick={() => balShowHideCall()}
                                sx={{ color: "white", marginTop: "-5px", marginLeft: "5px", cursor: "pointer" }} />}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1, mt: 1.5 }}>
                            <Typography
                                sx={{
                                    fontSize: { xs: '1rem', lg: '1.2rem' },
                                    textAlign: { xs: 'center', md: 'left' },
                                    fontWeight: 'bold'
                                }}
                            >
                                {balShow ? <>&#36; {spotBalPortfolio?.loader ? 'Loading...' : spotBalPortfolio?.estimated ? spotBalPortfolio?.estimated : 0.00}</> : <>***Balance hidden***</>}
                            </Typography>
                            <Typography
                                sx={{
                                    // fontSize: { xs: '1rem', lg: '1.2rem' },
                                    textAlign: { xs: 'center', md: 'left' },
                                    fontWeight: 'bold',
                                    mt: '-1.5px'
                                }}
                            > {
                                    loadingUsdt ? <Spinner animation="border" variant="light" size="sm" /> :
                                        <TransferModalUSDT avl={(walletDataUSDT?.amount || 0).toFixed(2)} firstCurrency={walletDataUSDT?.currencyDetailsDB?.currencySymbol} image={walletDataUSDT?.currencyDetailsDB?.image} fundMoveUSDT={fundMoveUSDT} fundUSDTLoading={fundUSDTLoading} title={<Button size='sm' variant="light" className='border text-primary p-0 px-2'>Transfer <AddCircleIcon fontSize='sm' /></Button>} />
                                }
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        textAlign: { xs: 'center', md: 'left' },
                        fontWeight: 'bold',
                        mt: { xs: 1.5, md: 0 },
                    }} className='d-flex justify-content-start align-items-center'>

                        <input type="text" className={`${foc ? 'text-light' : 'text-gray'} searchGlassFutures`} style={{ borderRadius: '30px 0 0px 30px', paddingLeft: '12px' }} placeholder="Search"
                            value={search} onChange={(e) => {
                                setSearch(e.target.value)
                            }} onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    searchFuncUSDT();
                                }
                            }} onFocus={() => { setFoc(() => true); }} onBlur={() => {
                                setTimeout(() => {
                                    setFoc(() => false);
                                }, 100);
                            }}
                        />
                        <button className='searchBtnFutures' style={{ borderRadius: '0 30px 30px 0', paddingLeft: '0px', paddingRight: '12px' }}
                            onClick={() => searchFuncUSDT()}
                        ><SearchIcon fontSize='sm' /></button>

                    </Box>
                </Box>

                <Box
                    sx={{
                        mt: 2,
                    }}
                >
                    {
                        (spotBalPortfolio?.loader || (reState && reState?.length > 0)) &&
                        <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', maxHeight: { xs: 'calc(86vh - 200px)', sm: 'calc(92vh - 200px)', md: 'calc(96vh - 200px)' } }}>
                            <Table stickyHeader aria-label="sticky table" sx={customStyle} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">Token</TableCell>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">Avl</TableCell>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">Hold</TableCell>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">Total</TableCell>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">AVG</TableCell>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">Value <SwapVertIcon sx={{ cursor: 'pointer', fontWeight: 600, fontSize: { xs: '1rem', lg: '1.3rem' } }}
                                            onClick={() => setSort((el) => el == 1 ? 2 : 1)}
                                        /></TableCell>
                                        <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">PnL</TableCell>
                                        <TableCell sx={{ width: 20, pl: 0, pr: 1, backgroundColor: '#3a3838', fontWeight: 600, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {spotBalPortfolio?.loader ? <React.Fragment >
                                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                            <TableCell component="th" scope="row" sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }}></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }} align="left"><Box sx={{ display: { xs: 'block', sm: 'none' } }}><Spinner animation="border" variant="light" size="sm" /></Box></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }} align="left"></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }} align="left"></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }} align="left"><Box sx={{ display: { xs: 'none', sm: 'block' } }}><Spinner animation="border" variant="light" size="sm" /></Box></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }} align="left"></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, }} align="left"></TableCell>
                                            <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120 }} align="left"></TableCell>
                                        </TableRow>
                                    </React.Fragment> : reState && reState.map((row) => (
                                        <CustomTableRowUSDT key={row?._id} row={row} balShow={balShow} loading={spotBalPortfolio?.loader} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Box>
            </Box>
            {!spotBalPortfolio?.loader && (!reState || reState?.length == 0) && (
                <div className="text-center mx-auto mt-5">
                    <img
                        className="no-record-image"
                        src="/assets/no-re.png"
                        alt="no-record"
                    />
                    <br />
                    <span className="text-center">No Records Found</span>
                </div>
            )}
        </div>
    );
};

export default WalletListSpotUsdt;