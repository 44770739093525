import React, { useEffect, useState } from "react";

const SpotOrderBookUsdt = (props) => {

  // console.log(props)
  const [maxVal, setMaxVal] = useState(0);

  // Calculate maxVal based on orderBookData
  useEffect(() => {
    let getMaxValue = 0;
    props.viewOrderList && props.viewOrderList.forEach((order) => {
      const total = parseFloat(order[1]) * parseFloat(order[0]);
      if (total > getMaxValue) {
        getMaxValue = total;
      }
    });
    setMaxVal(() => getMaxValue);
  }, [props.viewOrderList]);
  return (
    <>
      <ul className="mb-0 ps-0">
        {
          props.viewOrderList && props.viewOrderList.length > 0
            ?
            props.viewOrderList.slice(0, props.viewLimit).map((elmObj, index) => {
              const [price, amount, sum] = elmObj;
              const classIndexbased = index === 0 ? "m-0 p-0" : "";
              let cumulativeSum = 0;
              cumulativeSum += parseFloat(amount);
              const total = parseFloat(amount) * parseFloat(price);
              const perVal = (total / maxVal) * 100;
              const clrBarClass = props.orderListType === 'all' || props.orderListType === 'sell' ? "ask-bar" : "bid_bar_imp";
              const clrClass = props.orderListType === 'all' || props.orderListType === 'sell' ? "color-red-futures" : "color-green-futures";
              const transitionStyle = {
                transition: "opacity 0.5s"
              };

              return (
                <>
                  <li className={classIndexbased} key={index}>
                    <div className="d-flex flex-row mobile-justify-content">
                      <div className={"progress-bar " + (clrBarClass)} style={{
                        width: perVal + "%",
                        transition: "width 0.5s" // Apply transition effect to width property
                      }}></div>
                      {/* <div className={"progress-table-1 " + (clrClass)}><span className={'orderbook-text-2'}>{decimalValueFunc(elmObj._id, priceDecimal)}</span></div> */}
                      <div className={"progress-table-1 text-start " + clrClass} ><span style={transitionStyle} className={'orderbook-text-4'} onClick={() => props.handleSelectedPriceOrderBook(price)}>{price}</span></div>
                      <div className="progress-table-3 text-end pe-4" ><span style={transitionStyle} className='orderbook-text-4'>{amount}</span></div>
                      <div className="progress-table-2 text-end" ><span style={transitionStyle} className='orderbook-text-4'>{sum}</span></div>
                    </div>
                  </li>
                </>)
            })
            :
            ""
        }
      </ul>
    </>
  );
};

export default SpotOrderBookUsdt;
