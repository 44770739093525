import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import '../../../assets/style.css';
import { toast } from '../../../core/lib/toastAlert';
import Spinner from 'react-bootstrap/Spinner';
import SpotBelowInfoUsdt from './SpotBelowInfoUsdt';
import { useNavigate } from 'react-router-dom';

const SpotTradeFormUsdt = ({ pairDetails, priceStatic, setPriceStatic, handleClickOpenOrderConfirmation, amount, setAmount, submitTrade, myProfile, createSubAccount, spotBalance, fromSymbolName, quantity, setQuantity }) => {
    const [selectedItem, setSelectedItem] = useState('buy');
    const [tabMenu, setTabMenu] = useState('Limit')
    const [dropDownMenu, setDropDownMenu] = useState({ text: 'Advance', isOpen: false })
    const [stop, setStop] = useState(null);
    const [warningText, setWarningText] = useState(null)
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        setPriceStatic((data) => data)
    }, [pairDetails?.data?.price])
    // useEffect(() => {
    //     const coinPrice = +priceStatic
    //     const total = (coinPrice * amount);
    //     setQuantity(() => total == 0 || !total || total == null ? null : total.toFixed(pairDetails?.quoteDecimal))
    // }, [priceStatic])

    const makeNull = () => {
        setAmount(() => '')
        setQuantity(() => '')
        setStop(() => '')
        setPriceStatic(() => +(pairDetails?.data?.price))

    }

    const handleSliderChange = (newValue) => {
        const qn = quantity;
        const amnt = amount;
        setQuantity(() => 0)
        setAmount(() => 0)
        const coinPrice = +priceStatic;
        // console.log(spotBalance)
        let side = selectedItem === 'buy' ? 1 : 0;
        const balance = ((selectedItem === 'sell' && tabMenu.includes('Stop')) ? (+spotBalance?.['trade']?.[fromSymbolName(pairDetails?.data?.symbol, side)]?.available) + (+spotBalance?.['trade']?.[fromSymbolName(pairDetails?.data?.symbol, side)]?.holds) : (+spotBalance?.['trade']?.[fromSymbolName(pairDetails?.data?.symbol, side)]?.available)) || 0;

        if (selectedItem === 'buy') {
            const totalCoinV2 = +(balance) * (newValue / 100);
            const amountChanged = +(totalCoinV2) / coinPrice;
            const minSize = +(pairDetails?.pairDB?.minTrade)
            // const minSize = (Number(pairDetails?.data?.baseMinSize) + (Number(pairDetails?.data?.baseMinSize) * (pairDetails?.pairDB?.takerFeeWithKYC / 100))).toFixed(pairDetails?.quoteDecimal);
            // const maxSize = (Number(pairDetails?.data?.baseMaxSize) - (Number(pairDetails?.data?.baseMaxSize) * (pairDetails?.pairDB?.takerFeeWithKYC / 100))).toFixed(pairDetails?.quoteDecimal);

            if (amountChanged < minSize) {
                return setWarningText(() => <span style={{ color: 'rgb(255, 75, 94)', fontWeight: 700 }}>The order quantity must be greater than {minSize}.</span>)
            }
            if (parseFloat(amountChanged.toFixed(pairDetails?.baseDecimal)) == amnt) {
                setQuantity(() => qn)
                setAmount(() => amnt)
                return;
            }
            setWarningText(() => null)
            setQuantity(() => (totalCoinV2.toFixed(pairDetails?.quoteDecimal)))
            setAmount(() => parseFloat(amountChanged.toFixed(pairDetails?.baseDecimal)))
            return;
        }
        else if (selectedItem === 'sell') {
            const amountChanged = +(balance) * (newValue / 100);
            const totalCoinV2 = amountChanged * coinPrice;
            // const totalCoin = coinPrice * amountChanged;
            // console.log(balance,totalCoinV2,amountChanged)
            // const minSize = (Number(pairDetails?.data?.baseMinSize) - (Number(pairDetails?.data?.baseMinSize) * (pairDetails?.pairDB?.takerFeeWithKYC / 100))).toFixed(pairDetails?.quoteDecimal);
            // const maxSize = (Number(pairDetails?.data?.baseMaxSize) - (Number(pairDetails?.data?.baseMaxSize) * (pairDetails?.pairDB?.takerFeeWithKYC / 100))).toFixed(pairDetails?.quoteDecimal);
            // const minSize = +(pairDetails?.pairDB?.minTrade)
            // console.log(amountChanged,pairDetails?.data?.baseMinSize,pairDetails?.data?.baseMaxSize,balance,spotBalance)
            if ((amountChanged < Number(pairDetails?.data?.baseMinSize)) || amountChanged > Number(pairDetails?.data?.baseMaxSize)) {
                return setWarningText(() => <span style={{ color: 'rgb(255, 75, 94)', fontWeight: 700 }}>The order quantity must be greater than {pairDetails?.data?.baseMinSize} and lower than {pairDetails?.data?.baseMaxSize}.</span>)
            }
            if (parseFloat(amountChanged.toFixed(pairDetails?.baseDecimal)) == amnt) {
                setQuantity(() => qn)
                setAmount(() => amnt)
                return;
            }
            setWarningText(() => null)
            setQuantity(() => (totalCoinV2.toFixed(pairDetails?.quoteDecimal)))
            setAmount(() => parseFloat(amountChanged.toFixed(pairDetails?.baseDecimal)))
            return;
        }
    };

    const tradePreliminary = async (side) => {

        if ((!amount || amount == 0 || (tabMenu !== 'Market' && tabMenu !== 'Stop Market' && !priceStatic))) return toast({ type: "error", message: "Please fill up" });

        let tradeParams = {
            side: side.toLowerCase(), //buy sell
            symbol: pairDetails.data?.symbol,
            size: amount.toString(),
            type: tabMenu.toLowerCase(),
            remark: myProfile?._id,
            tradeType: 'TRADE',
            total: quantity,
            baseDecimal: pairDetails?.baseDecimal || 0,
            priceDecimal: pairDetails?.priceDecimal || 0,
            quoteDecimal: pairDetails?.baseDecimal || 0,
            price: priceStatic.toString()
        }
        if (tabMenu === 'Limit' || tabMenu === 'Stop Limit') {
            tradeParams.price = priceStatic.toString()
        }
        if (tabMenu === 'Stop Limit' || tabMenu === 'Stop Market') {
            tradeParams.stopPrice = stop.toString()
        }
        const isPermit = sessionStorage.getItem('confirm');
        if (isPermit == 'true') {

            const finalSub = await submitTrade(tradeParams)
            if (finalSub) {
                setAmount(() => '')
                setQuantity(() => '')
            }
        }
        else {
            handleClickOpenOrderConfirmation(tradeParams)
        }
    }
    const handleAmountChange = (e) => {
        const value = e.target.value;
        // const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,5}$`);
        const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,${pairDetails?.baseDecimal}}$`);
        if (regex.test(value) || value === '') {
            setAmount(() => value);
            const coinPrice = +priceStatic
            const total = (coinPrice * value);
            setQuantity(() => total.toFixed(pairDetails?.quoteDecimal))
            setWarningText(() => null)
        }
    };
    const handlePriceChange = (e) => {
        const value = e.target.value;
        // const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,5}$`);
        const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,${pairDetails?.quoteDecimal}}$`);
        if (regex.test(value) || value === '') {
            setPriceStatic(() => value);

            const coinPrice = +value
            const total = (coinPrice * amount);
            setQuantity(() => total.toFixed(pairDetails?.quoteDecimal))
            setWarningText(() => null)
        }
    };
    const handleStopChange = (e) => {
        const value = e.target.value;
        // const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,5}$`);
        const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,${pairDetails?.quoteDecimal}}$`);
        if (regex.test(value) || value === '') {
            setStop(() => value);
        }
    };
    const handleTotalChange = (e) => {
        const value = e.target.value;
        const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,${pairDetails?.quoteDecimal}}$`);
        if (regex.test(value) || value === '') {
            setQuantity(() => value);

            const total = +value
            const amount = (total / priceStatic);
            setAmount(() => amount.toFixed(pairDetails?.baseDecimal))
            setWarningText(() => null)
        }
    };
    const checkOnBlur = (e) => {
        const minValue = pairDetails?.data?.baseMinSize
        if (e.target.value < Number(minValue)) {
            // toast({ type: 'error', message: `Min ${minValue}` })
        }
    }
    const OpenFuturesAccount = <div><div className='w-100'><button disabled={loading} onClick={async () => {
        setLoading(() => true);
        await createSubAccount(myProfile?.email)
        setLoading(() => false)
    }} >
        {loading ? <>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
            <span className='ps-2'>Loading...</span></> : 'Trade Account Create'}</button></div></div>
    const styleTabOne = {
        background: '#535151'
    }
    return (
        <div style={{ background: '#2E2C2C', height: 'fit-content' }} className='px-3 py-2 '>
            <div className='top-button-spot'>
                <div className='w-100'>
                    <button style={selectedItem === 'sell' ? styleTabOne : null} onClick={() => {
                        setSelectedItem(() => 'buy')
                        setTabMenu(() => 'Limit'); setDropDownMenu((el) => { return { ...el, isOpen: false, text: 'Advance' } }); makeNull()
                    }}
                    >
                        Buy
                    </button>
                </div>
                <div className='w-100'>
                    <button style={selectedItem === 'buy' ? styleTabOne : null} onClick={() => {
                        setSelectedItem(() => 'sell')
                        makeNull()
                    }}
                    >
                        Sell
                    </button>
                </div>
            </div>
            <div className='menu-option-part pt-3'>
                <p className={`${tabMenu === 'Limit' ? 'text-active' : ''} cursor-pointer pb-1`} onClick={() => { setTabMenu(() => 'Limit'); setDropDownMenu((el) => { return { ...el, isOpen: false, text: 'Advance' } }); makeNull() }}>Limit</p>
                {/* <p className={`${tabMenu === 'Market' ? 'text-active' : ''} cursor-pointer pb-1`} onClick={() => { setTabMenu(() => 'Market'); setDropDownMenu((el) => { return { ...el, isOpen: false, text: 'Advance' } }); makeNull() }}>Market</p> */}
                <div tabIndex='0' className={`${(tabMenu === 'Stop Limit' || tabMenu === 'Stop Market') ? 'text-active' : ''} cursor-pointer pb-1 position-relative ${selectedItem === 'buy' ? 'd-none' : 'd-inline-block'}`} onBlur={() => setDropDownMenu(el => { return { ...el, isOpen: false } })}>
                    <p onClick={() => { setDropDownMenu(el => { return { ...el, isOpen: !el.isOpen } }); }} className={`${(tabMenu === 'Stop Limit' || tabMenu === 'Stop Market') ? 'text-light' : ''}`}>{dropDownMenu?.text} {dropDownMenu?.isOpen ? <>&uarr;</> : <>&darr;</>}</p>
                    <div className={`${dropDownMenu?.isOpen ? '' : 'd-none'} position-absolute px-2 py-2 dropdownOfTrade`} style={{ zIndex: 1, top: '120%' }}>
                        <p onClick={() => { setDropDownMenu((el) => { return { ...el, isOpen: false, text: 'Stop Limit' } }); setTabMenu(() => 'Stop Limit'); makeNull() }}>Stop Limit</p>
                        {/* <p onClick={() => { setDropDownMenu((el) => { return { ...el, isOpen: false, text: 'Stop Market' } }); setTabMenu(() => 'Stop Market'); makeNull() }}>Stop Market</p> */}
                    </div>
                </div>
            </div>
            {tabMenu === 'Market' ?
                <div className='pt-2'>
                    <div>
                        <InputGroup className="mb-2 selectOfTrade" >
                            <Form.Control aria-label="Text input with dropdown button" type='text' readOnly className='inputOfTrade text-center' value={'Best Market Price'} />
                        </InputGroup>

                        <Form.Label htmlFor="basic-url" style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                        }}>Amount ({pairDetails?.data?.baseCurrency}): </Form.Label>
                        <InputGroup className="mb-2 selectOfTrade" style={{ border: warningText ? '1px solid rgb(255, 75, 94)' : '0', borderRadius: '0.375rem' }}>
                            <Form.Control aria-label="Text input with text" type='number' className='inputOfTrade'
                                // placeholder={pairDetails?.data?.baseMinSize ? `Min ${pairDetails?.data?.baseMinSize}` : ''} 
                                placeholder="Amount" value={amount} onChange={handleAmountChange} onBlur={checkOnBlur} />
                            <InputGroup.Text id="Text input with text" className='sideBtn'>{pairDetails?.data?.baseCurrency} </InputGroup.Text>
                        </InputGroup>
                        <div className='handleCostMaxText my-0'><p>{warningText}</p></div>
                        {/* <div className='handleCostMaxText mt-0 mb-2'><p>{`Min ${pairDetails?.data?.baseMinSize || 0} ${pairDetails?.data?.baseCurrency}`}</p></div> */}

                        <div className='my-2 percentage-button'>
                            <button onClick={() => handleSliderChange(25)}>25%</button>
                            <button onClick={() => handleSliderChange(50)}>50%</button>
                            <button onClick={() => handleSliderChange(75)}>75%</button>
                            <button onClick={() => handleSliderChange(100)}>100%</button>
                        </div>
                        <div className='handleTextBS mt-0 mb-2'>
                            <p className='pt-1'>Available USDT: <span>
                                {spotBalance?.['trade']?.['USDT']?.available ? (+spotBalance?.['trade']?.['USDT']?.available)?.toFixed(2) : 0.00}
                            </span></p>
                        </div>
                        <Form.Label htmlFor="basic-url" style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                        }}>Total ({pairDetails?.data?.quoteCurrency}): </Form.Label>
                        <InputGroup className="mb-2 selectOfTrade" >
                            <Form.Control aria-label="Text input with dropdown button" type='number'
                                placeholder={`Min ${pairDetails?.pairDB?.minTrade || 0} ${pairDetails?.data?.quoteCurrency || ''}`} className='inputOfTrade' value={quantity} onChange={handleTotalChange} />
                        </InputGroup>
                        <div className='handleCostMaxText mt-0 mb-2'><p>{`Min ${pairDetails?.pairDB?.minTrade || 0} ${pairDetails?.data?.quoteCurrency || ''}`}</p></div>
                        <div className='my-3' >
                            <div className='lastPartButton mt-2'>
                                {(myProfile?.futuresSubAccount?.isActive && myProfile?.futuresSubAccount.uid) ?
                                    <SpotBelowInfoUsdt selectedItem={selectedItem} tradePreliminary={tradePreliminary} pairDetails={pairDetails} />
                                    : (!myProfile) ? <div className='trading-page-order-tabs-section h-auto'>
                                        <span className='trade-bottom-login-text-1'>
                                            <span className='fc-g cursor-pointer' onClick={() => navigate('/login')}>Login </span>(or)
                                            <span className='fc-g cursor-pointer' onClick={() => navigate('/register')}> Register Now</span> to trade
                                        </span>
                                    </div> : OpenFuturesAccount}
                                {/* <div className='handleCostMaxText text-end mt-2'><p>Fee Level</p></div> */}
                            </div>
                        </div>
                    </div>
                </div>
                : tabMenu === 'Limit' ?
                    <div className='pt-2'>
                        <div>
                            <Form.Label htmlFor="basic-url" style={{
                                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                            }}>Price ({pairDetails?.data?.quoteCurrency}): </Form.Label>
                            <InputGroup className="mb-2 selectOfTrade" >
                                <Form.Control aria-label="Text input with dropdown button" type='number' className='inputOfTrade' value={priceStatic} onChange={handlePriceChange} />
                            </InputGroup>

                            <Form.Label htmlFor="basic-url" style={{
                                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                            }}>Amount ({pairDetails?.data?.baseCurrency}): </Form.Label>
                            <InputGroup className="mb-2 selectOfTrade" style={{ border: warningText ? '1px solid rgb(255, 75, 94)' : '0', borderRadius: '0.375rem' }}>
                                <Form.Control aria-label="Text input with text" type='number' className='inputOfTrade'
                                    // placeholder={pairDetails?.data?.baseMinSize ? `Min ${selectedItem === 'buy' && tabMenu.includes('Limit') ? (Number(pairDetails?.data?.baseMinSize) + (Number(pairDetails?.data?.baseMinSize) * (pairDetails?.pairDB?.takerFeeWithKYC / 100))).toFixed(pairDetails?.quoteDecimal) : pairDetails?.data?.baseMinSize}` : ''} 
                                    placeholder="Amount" value={amount} onChange={handleAmountChange} onBlur={checkOnBlur} />
                                <InputGroup.Text id="Text input with text" className='sideBtn'>{pairDetails?.data?.baseCurrency} </InputGroup.Text>
                            </InputGroup>
                            <div className='handleCostMaxText my-0'><p>{warningText}</p></div>
                            {/* <div className='handleCostMaxText mt-0 mb-2'><p>{`Min ${selectedItem === 'buy' && tabMenu.includes('Limit') ? (Number(pairDetails?.data?.baseMinSize) + (Number(pairDetails?.data?.baseMinSize) * (pairDetails?.pairDB?.takerFeeWithKYC / 100))).toFixed(pairDetails?.quoteDecimal) : pairDetails?.data?.baseMinSize} ${pairDetails?.data?.baseCurrency}`}</p></div> */}

                            <div className='my-2 percentage-button'>
                                <button onClick={() => handleSliderChange(25)}>25%</button>
                                <button onClick={() => handleSliderChange(50)}>50%</button>
                                <button onClick={() => handleSliderChange(75)}>75%</button>
                                <button onClick={() => handleSliderChange(100)}>100%</button>
                            </div>
                            <div className='handleTextBS mt-0 mb-2'>
                                <p className='pt-1'>Available USDT: <span>
                                    {spotBalance?.['trade']?.['USDT']?.available ? (+spotBalance?.['trade']?.['USDT']?.available)?.toFixed(2) : 0.00}
                                </span></p>
                            </div>
                            <Form.Label htmlFor="basic-url" style={{
                                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                            }}>Total ({pairDetails?.data?.quoteCurrency}): </Form.Label>
                            <InputGroup className="mb-2 selectOfTrade" >
                                <Form.Control aria-label="Text input with dropdown button" type='number' className='inputOfTrade' placeholder={`Min ${pairDetails?.pairDB?.minTrade || 0} ${pairDetails?.data?.quoteCurrency || ''}`} value={quantity} onChange={handleTotalChange} />
                            </InputGroup>
                            <div className='handleCostMaxText mt-0 mb-2'><p>{`Min ${pairDetails?.pairDB?.minTrade || 0} ${pairDetails?.data?.quoteCurrency || ''}`}</p></div>
                            <div className='my-3' >
                                <div className='lastPartButton mt-2'>
                                    {(myProfile?.futuresSubAccount?.isActive && myProfile?.futuresSubAccount.uid) ?
                                        <SpotBelowInfoUsdt selectedItem={selectedItem} tradePreliminary={tradePreliminary} pairDetails={pairDetails} />
                                        : (!myProfile) ? <div className='trading-page-order-tabs-section h-auto'>
                                            <span className='trade-bottom-login-text-1'>
                                                <span className='fc-g cursor-pointer' onClick={() => navigate('/login')}>Login </span>(or)
                                                <span className='fc-g cursor-pointer' onClick={() => navigate('/register')}> Register Now</span> to trade
                                            </span>
                                        </div> : OpenFuturesAccount}
                                    {/* <div className='handleCostMaxText text-end mt-2'><p>Fee Level</p></div> */}
                                </div>
                            </div>
                        </div>
                    </div> : tabMenu === 'Stop Limit' ? <div className='pt-2'>
                        <div>
                            <Form.Label htmlFor="basic-url" style={{
                                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                            }}>Stop ({pairDetails?.data?.quoteCurrency}): </Form.Label>
                            <InputGroup className="mb-2 selectOfTrade" >
                                <Form.Control aria-label="Text input with dropdown button stop limit" type='number' className='inputOfTrade' placeholder='Stop Price' value={stop} onChange={handleStopChange} />
                            </InputGroup>
                            <Form.Label htmlFor="basic-url" style={{
                                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                            }}>Price ({pairDetails?.data?.quoteCurrency}): </Form.Label>
                            <InputGroup className="mb-2 selectOfTrade" >
                                <Form.Control aria-label="Text input with dropdown button" type='number' className='inputOfTrade' value={priceStatic} onChange={handlePriceChange} />
                            </InputGroup>
                            <Form.Label htmlFor="basic-url" style={{
                                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                            }}>Amount ({pairDetails?.data?.baseCurrency}): </Form.Label>
                            <InputGroup className="mb-2 selectOfTrade" style={{ border: warningText ? '1px solid rgb(255, 75, 94)' : '0', borderRadius: '0.375rem' }}>
                                <Form.Control aria-label="Text input with text" type='number' className='inputOfTrade'
                                    // placeholder={pairDetails?.data?.baseMinSize ? `Min ${selectedItem === 'buy' && tabMenu.includes('Limit') ? (Number(pairDetails?.data?.baseMinSize) + (Number(pairDetails?.data?.baseMinSize) * (pairDetails?.pairDB?.takerFeeWithKYC / 100))).toFixed(pairDetails?.quoteDecimal) : pairDetails?.data?.baseMinSize}` : ''} 
                                    placeholder="Amount" value={amount} onChange={handleAmountChange} onBlur={checkOnBlur} />
                                <InputGroup.Text id="Text input with text" className='sideBtn'>{pairDetails?.data?.baseCurrency} </InputGroup.Text>
                            </InputGroup>
                            <div className='handleCostMaxText my-0'><p>{warningText}</p></div>
                            {/* <div className='handleCostMaxText mt-0 mb-2'><p>{`Min ${selectedItem === 'buy' && tabMenu.includes('Limit') ? (Number(pairDetails?.data?.baseMinSize) + (Number(pairDetails?.data?.baseMinSize) * (pairDetails?.pairDB?.takerFeeWithKYC / 100))).toFixed(pairDetails?.quoteDecimal) : pairDetails?.data?.baseMinSize} ${pairDetails?.data?.baseCurrency}`}</p></div> */}

                            <div className='my-2 percentage-button'>
                                <button onClick={() => handleSliderChange(25)}>25%</button>
                                <button onClick={() => handleSliderChange(50)}>50%</button>
                                <button onClick={() => handleSliderChange(75)}>75%</button>
                                <button onClick={() => handleSliderChange(100)}>100%</button>
                            </div>
                            <div className='handleTextBS mt-0 mb-2'>
                                <p className='pt-1'>Available USDT: <span>
                                    {spotBalance?.['trade']?.['USDT']?.available ? (+spotBalance?.['trade']?.['USDT']?.available)?.toFixed(2) : 0.00}
                                </span></p>
                            </div>
                            <Form.Label htmlFor="basic-url" style={{
                                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                            }}>Total ({pairDetails?.data?.quoteCurrency}): </Form.Label>
                            <InputGroup className="mb-2 selectOfTrade" >
                                <Form.Control aria-label="Text input with dropdown button" type='number' className='inputOfTrade' placeholder={`Min ${pairDetails?.pairDB?.minTrade / 2 || 0} ${pairDetails?.data?.quoteCurrency || ''}`} value={quantity} onChange={handleTotalChange} />
                            </InputGroup>
                            <div className='handleCostMaxText mt-0 mb-2'><p>{`Min ${pairDetails?.pairDB?.minTrade / 2 || 0} ${pairDetails?.data?.quoteCurrency || ''}`}</p></div>
                            <div className='my-3' >
                                <div className='lastPartButton mt-2'>
                                    {(myProfile?.futuresSubAccount?.isActive && myProfile?.futuresSubAccount.uid) ?
                                        <SpotBelowInfoUsdt selectedItem={selectedItem} tradePreliminary={tradePreliminary} pairDetails={pairDetails} />
                                        : (!myProfile) ? <div className='trading-page-order-tabs-section h-auto'>
                                            <span className='trade-bottom-login-text-1'>
                                                <span className='fc-g cursor-pointer' onClick={() => navigate('/login')}>Login </span>(or)
                                                <span className='fc-g cursor-pointer' onClick={() => navigate('/register')}> Register Now</span> to trade
                                            </span>
                                        </div> : OpenFuturesAccount}
                                    {/* <div className='handleCostMaxText text-end mt-2'><p>Fee Level</p></div> */}
                                </div>
                            </div>
                        </div>
                    </div> : tabMenu === 'Stop Market' ? <div className='pt-2'>
                        <div>
                            <Form.Label htmlFor="basic-url" style={{
                                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                            }}>Stop ({pairDetails?.data?.quoteCurrency}): </Form.Label>
                            <InputGroup className="mb-2 selectOfTrade" >
                                <Form.Control aria-label="Text input with dropdown button2 stop market" type='number' className='inputOfTrade' placeholder='Stop Price' value={stop} onChange={handleStopChange} />
                            </InputGroup>
                            <InputGroup className="mb-2 selectOfTrade" >
                                <Form.Control aria-label="Text input with dropdown button" type='text' readOnly className='inputOfTrade text-center' value={'Best Market Price'} />
                            </InputGroup>
                            <Form.Label htmlFor="basic-url" style={{
                                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                            }}>Amount ({pairDetails?.data?.baseCurrency}): </Form.Label>
                            <InputGroup className="mb-2 selectOfTrade" style={{ border: warningText ? '1px solid rgb(255, 75, 94)' : '0', borderRadius: '0.375rem' }}>
                                <Form.Control aria-label="Text input with text" type='number' className='inputOfTrade'
                                    // placeholder={pairDetails?.data?.baseMinSize ? `Min ${pairDetails?.data?.baseMinSize}` : ''} 
                                    placeholder="Amount" value={amount} onChange={handleAmountChange} onBlur={checkOnBlur} />
                                <InputGroup.Text id="Text input with text" className='sideBtn'>{pairDetails?.data?.baseCurrency} </InputGroup.Text>
                            </InputGroup>
                            <div className='handleCostMaxText my-0'><p>{warningText}</p></div>

                            <div className='my-2 percentage-button'>
                                <button onClick={() => handleSliderChange(25)}>25%</button>
                                <button onClick={() => handleSliderChange(50)}>50%</button>
                                <button onClick={() => handleSliderChange(75)}>75%</button>
                                <button onClick={() => handleSliderChange(100)}>100%</button>
                            </div>
                            <div className='handleTextBS mt-0 mb-2'>
                                <p className='pt-1'>Available USDT: <span>
                                    {spotBalance?.['trade']?.['USDT']?.available ? (+spotBalance?.['trade']?.['USDT']?.available)?.toFixed(2) : 0.00}
                                </span></p>
                            </div>
                            <Form.Label htmlFor="basic-url" style={{
                                color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                            }}>Total ({pairDetails?.data?.quoteCurrency}): </Form.Label>
                            <InputGroup className="mb-2 selectOfTrade" >
                                <Form.Control aria-label="Text input with dropdown button" type='number' className='inputOfTrade' placeholder={`Min ${pairDetails?.pairDB?.minTrade || 0} ${pairDetails?.data?.quoteCurrency || ''}`} value={quantity} onChange={handleTotalChange} />
                            </InputGroup>
                            <div className='handleCostMaxText mt-0 mb-2'><p>{`Min ${pairDetails?.pairDB?.minTrade || 0} ${pairDetails?.data?.quoteCurrency || ''}`}</p></div>
                            <div className='my-3' >
                                <div className='lastPartButton mt-2'>
                                    {(myProfile?.futuresSubAccount?.isActive && myProfile?.futuresSubAccount.uid) ?
                                        <SpotBelowInfoUsdt selectedItem={selectedItem} tradePreliminary={tradePreliminary} pairDetails={pairDetails} />
                                        : (!myProfile) ? <div className='trading-page-order-tabs-section h-auto'>
                                            <span className='trade-bottom-login-text-1'>
                                                <span className='fc-g cursor-pointer' onClick={() => navigate('/login')}>Login </span>(or)
                                                <span className='fc-g cursor-pointer' onClick={() => navigate('/register')}> Register Now</span> to trade
                                            </span>
                                        </div> : OpenFuturesAccount}
                                    {/* <div className='handleCostMaxText text-end mt-2'><p>Fee Level</p></div> */}
                                </div>
                            </div>
                        </div>
                    </div> : null
            }

            <div className='mt-4'>
                <h4 style={{
                    color: '#FFF', fontFamily: 'Roboto', fontSize: '14px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                }}>Asset Overview</h4>
                <div>
                    <h4 className='mb-1' style={{
                        color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                    }}>Trading Account</h4>
                    <div className='d-flex justify-content-between align-items-center pb-1'>
                        <p style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'
                        }}>{pairDetails?.data?.baseCurrency} (AVL)</p>
                        <p style={{
                            color: '#FFFF', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'
                        }}>{spotBalance?.['trade']?.[pairDetails?.data?.baseCurrency]?.available ? (+spotBalance?.['trade']?.[pairDetails?.data?.baseCurrency]?.available)?.toFixed(pairDetails?.baseDecimal) : 0.00}</p>
                    </div>
                    <div className='d-flex justify-content-between align-items-center pb-1'>
                        <p style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'
                        }}>{pairDetails?.data?.baseCurrency} (Hold)</p>
                        <p style={{
                            color: '#FFFF', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'
                        }}>{spotBalance?.['trade']?.[pairDetails?.data?.baseCurrency]?.holds ? (+spotBalance?.['trade']?.[pairDetails?.data?.baseCurrency]?.holds)?.toFixed(pairDetails?.baseDecimal) : 0.00}</p>
                    </div>
                    <div className='d-flex justify-content-between align-items-center pb-1'>
                        <p style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'
                        }}>{pairDetails?.data?.quoteCurrency}</p>
                        <p style={{
                            color: '#FFFF', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'
                        }}>{spotBalance?.['trade']?.[pairDetails?.data?.quoteCurrency]?.available ? (+spotBalance?.['trade']?.[pairDetails?.data?.quoteCurrency]?.available)?.toFixed(pairDetails?.quoteDecimal) : 0.00}</p>
                    </div>
                </div>
                {/* <div className='mt-1'>
                    <h4 className='mb-1' style={{
                        color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal',
                    }}>Finding Account</h4>
                    <div className='d-flex justify-content-between align-items-center pb-1'>
                        <p style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'
                        }}>{pairDetails?.data?.baseCurrency}</p>
                        <p style={{
                            color: '#FFFF', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'
                        }}>{spotBalance?.['main']?.[pairDetails?.data?.baseCurrency]?.available ? (+spotBalance?.['main']?.[pairDetails?.data?.baseCurrency]?.available)?.toFixed(pairDetails?.baseDecimal) : 0.00}</p>
                    </div>
                    <div className='d-flex justify-content-between align-items-center pb-1'>
                        <p style={{
                            color: '#C8C8C8', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'
                        }}>{pairDetails?.data?.quoteCurrency}</p>
                        <p style={{
                            color: '#FFFF', fontFamily: 'Roboto', fontSize: '12px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'
                        }}>{spotBalance?.['main']?.[pairDetails?.data?.quoteCurrency]?.available ? (+spotBalance?.['main']?.[pairDetails?.data?.quoteCurrency]?.available)?.toFixed(pairDetails?.quoteDecimal) : 0.00}</p>
                    </div>
                </div> */}
            </div>
        </div>

    );
};

export default SpotTradeFormUsdt;