
const authReducer = (state = {}, action = {}) => {
  /* eslint-disable indent */
  switch (action.type) {
    case 'SET_SITE_SETTINGS': {
      return {
        ...state,
        siteSettings: { ...action.payload }
      };
    }
    case 'SET_MY_PROFILE': {
      return {
        ...state,
        myProfile: { ...action.payload }
      };
    }
    case 'SET_P2P_SETTINGS': {
      return {
        ...state,
        p2pSettings: { ...action.payload }
      };
    }
    case 'SET_FUTURES_BALANCE': {
      return {
        ...state,
        futuresBalance: { ...action.payload }
      };
    }
    case 'SET_SPOT_BALANCE': {
      return {
        ...state,
        spotBalance: { ...action.payload }
      };
    }
    case 'SET_SPOT_AVLBALANCE': {
      return {
        ...state,
        spotBalance: { ...state.spotBalance, trade: { ...state.spotBalance.trade, [action.payload.currency]: { ...state.spotBalance.trade?.[action.payload.currency], available: action.payload?.available, holds: action.payload?.hold } } }
      };
    }
    case 'SET_SPOT_MAIN': {
      return {
        ...state,
        spotBalance: { ...state.spotBalance, main: { ...state.spotBalance.main, [action.payload.currency]: { ...state.spotBalance.main?.[action.payload.currency], available: action.payload?.available, holds: action.payload?.hold } } }
      };
    }
    default: return
  }

  /* eslint-enable indent */
};

export default authReducer;
