import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import imgTick from '../../../../assets/images/success.png'
import { Button } from 'react-bootstrap';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        borderTop: '1px solid rgba(200, 200, 200, 0.32)',
        borderBottom: '0'
    },
    '& .MuiDialogTitle-root': {
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.975rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    '& .MuiDialog-paper': {
        borderRadius: '8px',
        background: '#2E2C2C',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        color: '#FFF',
        fontFamily: "Open Sans",
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    }
}));

export default function OrderDetails({ open, handleClickOpen, handleClose, resMsg = null }) {
    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Fund Transfer
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <div>
                        <div className='d-flex justify-content-center align-items-center flex-column text-center pb-3'>
                            <div><img src={imgTick} alt='' className='img-fluid' /></div>
                            <div>
                                <h5 className='mb-3'>{resMsg ? resMsg : 'Done, Check After 30 Mins'}</h5>
                                {/* <p className='mb-2'>Order Size: 938.133 USDT</p>
                                <p className='mb-3'>Order Qty: 9 SOL</p> */}
                            </div>
                        </div>
                        <div className='w-100 dialog-bottom-button mb-1 pb-3'>
                            <Button variant="light" className='border text-primary' onClick={() => handleClose()}>OKAY</Button>
                        </div>
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
}