import React, { useState } from 'react';
import '../../../../assets/style.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const dataRow = [
    { label: 'Ranking', value: 'No 1', id: 1 },
    { label: 'Market Capitalization', value: '834739834 BTC', id: 2 },
    { label: 'Market Dominance Index', value: '49.78%', id: 3 },
    { label: 'Circulating Supply', value: '19,604,867 BTC', id: 4 },
    { label: 'Maximum Supply', value: '19,604,867 BTC', id: 5 },
    { label: 'Total', value: '19,604,867 BTC', id: 6 },
    { label: 'Issue Date', value: '2008-11-01', id: 7 },
    { label: 'Historical High', value: '$783498230923', id: 8 },
    { label: 'Historical Low', value: '$0.093493403', id: 9 },
    { label: 'Submit FeedBack', value: '', id: 10 },
]
const CoinInfoUsdt = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(() => newValue);
    };
    return (
        <div>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <div className='d-flex justify-content-start align-items-center py-3' style={{ gap: '10px' }}><img src='/assets/bit.png' alt='' className='img-fluid' /> <h5 className='genText mb-0' style={{ fontSize: '1.18rem' }}>BitCoin</h5></div>
                    {dataRow?.map(data => <div className='d-flex justify-content-between align-items-center handleCoinInfoLeft pb-2' key={data?.id}>
                        <p>{data?.label}</p><p>{data?.value}</p>
                    </div>)}
                    <p className='genTex pt-3' style={{ fontSize: '0.875rem', color: '#c7c7c7' }}>Data is provided by cmc and is reference Only. It is Presented on this basic and docnot serve as any representation or guarantee</p>
                </div>
                <div className='col-12 col-md-6'>
                    <div className='py-3'><h5 className='genText mb-0' style={{ fontSize: '1.18rem' }}>Links</h5></div>

                    <div className='w-100'>

                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                            sx={{
                                '& .MuiTabs-indicator': {
                                    borderRadius: '4px',
                                    background: '#10edb6',
                                    top: '20px'
                                },
                                '& .MuiTab-root': {
                                    color: 'white',
                                    textTransform: "capitalize",
                                    fontSize: "0.875rem",
                                    fontWeight: "400",
                                    fontFamily: "'Roboto', sans-serif ",
                                    fontStyle: "normal",
                                    padding: "auto",
                                },
                                '& .MuiTab-root.Mui-selected': {
                                    color: 'white',
                                    // fontWeight: "800"
                                },
                                '& .MuiButtonBase-root': {
                                    width: 'fit-content',
                                    padding: '0px 10px',
                                    minWidth: "auto",
                                    minHeight: "auto",
                                    // fontWeight: "800"
                                },
                                '& .MuiTabs-scroller': {
                                    overflow: 'auto !important',
                                    '&::-webkit-scrollbar': {
                                        display: 'none'  /* WebKit (Chrome, Safari, etc.) */
                                    }
                                },

                            }}
                        >
                            <Tab label="Official Site" {...a11yProps(0)} />
                            <Tab label="White Paper" {...a11yProps(1)} />
                            <Tab label="Block explorer" {...a11yProps(2)} />
                        </Tabs>
                        <TabPanel value={value} index={0} sx={{ paddingTop: '0px' }}>
                            <div className='handleCoinInfoRight'>
                                <p className='pb-1'>Intro</p>
                                <p className='text-justify'>Bitcoin (BTC) is a peer-to-peer cryptocurrency that aims to function as a means of exchange that is independent of any central authority. BTC can be transferred electronically in a secure, verifiable, and immutable way.
                                    Launched in 2009, BTC is the first virtual currency to solve the double-spending issue by timestamping transactions before broadcasting them to all of the nodes in the Bitcoin network. The Bitcoin Protocol offered a solution to the Byzantine Generals' Problem with a blockchain network structure, a notion first created by Stuart Haber and W. Scott Stornetta in 1991.
                                    Bitcoin’s whitepaper was published pseudonymously in 2008 by an individual, or a group, with the pseudonym “Satoshi Nakamoto”, whose underlying identity has still not been verified.
                                    The Bitcoin protocol uses an SHA-256d-based Proof-of-Work (PoW) algorithm to reach network consensus. Its network has a target block time of 10 minutes and a maximum supply of 21 million tokens, with a decaying token emission rate. To prevent fluctuation of the block time, the network's block difficulty is re-adjusted through an algorithm based on the past 2016 block times.
                                    With a block size limit capped at 1 megabyte, the Bitcoin Protocol has supported both the Lightning Network, a second-layer infrastructure for payment channels, and Segregated Witness, a soft-fork to increase the number of transactions on a block, as solutions to network scalability.</p>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>

                        </TabPanel>
                        <TabPanel value={value} index={2}>

                        </TabPanel>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default CoinInfoUsdt;