import React from 'react';
import {
    Box, TableCell,
    TableRow, Typography, IconButton,
    Button,
    Avatar
} from "@mui/material";
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';

const CustomTableRowUSDT = (props) => {
    const { row, balShow } = props;
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    // const isWithdraw = row?.currencyDetails?.chains?.some(el => el?.isWithdrawEnabled) ?? false;
    // const isDeposit = row?.currencyDetails?.chains?.some(el => el?.isDepositEnabled) ?? false;

    const avlForAsset = (Number(row?.available) + Number(row?.holds));
    const ltp = row?.currency === 'USDT' ? 1 : row?.price;
    let zero = 0;
    let zeroWithDec = zero.toFixed(2);
    const profitNum = (avlForAsset === '0' || !avlForAsset || avlForAsset === 0) ? zeroWithDec : (((+ltp) - row?.lastPurchasedPrice) * (+avlForAsset)).toFixed(2);
    const profitPercentage = (avlForAsset === '0' || !avlForAsset || avlForAsset === 0 || row?.lastPurchasedPrice == 0) ? zeroWithDec : ((ltp - row?.lastPurchasedPrice) / row?.lastPurchasedPrice * 100).toFixed(2);
    return (
        <React.Fragment >
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}
            >
                <TableCell component="th" scope="row" sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            if (row?.currency !== 'USDT') {
                                navigate("/spot-usdt/" + row?.currency + '-USDT')
                            }
                        }}
                    >
                        <Avatar
                            alt="Icon"
                            src={row?.currencyDetailsDB?.image}
                            sx={{ width: { xs: 20, lg: 30 }, height: { xs: 20, lg: 30 } }}
                        />
                        <Box>
                            <Typography sx={{ fontSize: { xs: '0.7rem', lg: '0.9rem' } }} className='text-nowrap'>{row?.currencyDetailsDB?.currencyName}</Typography>
                        </Box>
                    </Box>
                </TableCell>
                <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">{props?.loading ? "Loading..." : balShow ? (Number(row?.available).toFixed(row?.priceDecimal || 2)) : "******"}</TableCell>
                <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">{props?.loading ? "Loading..." : balShow ? (Number(row?.holds).toFixed(row?.priceDecimal || 2)) : "******"}</TableCell>
                <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">{props?.loading ? "Loading..." : balShow ? ((Number(row?.available) + Number(row?.holds)).toFixed(row?.priceDecimal || 2)) : "******"}</TableCell>
                <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">&#36; {props?.loading ? "Loading..." : balShow ? (row?.lastPurchasedPrice) : "******"}</TableCell>
                <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">&#36; {props?.loading ? "Loading..." : balShow ? (row?.value) : "******"}</TableCell>
                <TableCell sx={{ minWidth: window.innerWidth > 600 ? 90 : 120, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">{props?.loading ? "Loading..." : balShow ? <><span style={{ color: profitNum > 0 ? ' #21CA50' : '#ff4b5e' }}>{profitNum > 0 ? `+${profitNum}` : profitNum}</span> (<span style={{ color: profitPercentage > 0 ? '#21CA50' : '#ff4b5e' }}>{(profitPercentage != 'NaN' || !profitPercentage || profitPercentage == null || profitPercentage === Infinity) ? profitPercentage > 0 ? `+${profitPercentage}` : profitPercentage : zeroWithDec}%</span>)</> : "******"}</TableCell>
                <TableCell sx={{ width: 20, pl: 0, pr: 1, fontSize: { xs: '0.7rem', lg: '0.9rem' } }} align="left">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        sx={{ display: { xs: 'block', lg: 'none' } }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <Box sx={{ margin: 0, display: { xs: 'none', lg: 'flex' }, gap: '5px' }} className=' justify-content-end align-items-center w-100 my-3'>

                        <Box className='text-center'>
                            <Button
                                sx={{
                                    bgcolor: '#C8C8C8',
                                    padding: { xs: '2px 5px', lg: '2px 7px' },
                                    borderRadius: '20px',
                                    color: '#000',
                                    fontSize: { xs: '10px', lg: '11px' },
                                    textAlign: 'center',
                                    // mt: 3,
                                    cursor: 'pointer',
                                    lineHeight: 1.8,
                                    '&:disabled': {
                                        cursor: 'not-allowed',
                                        bgcolor: '#c8c8c8aa',
                                        color: '#000'
                                    },
                                    '&:hover': {
                                        color: 'white'
                                    },
                                    width: '100%'
                                }}
                                disabled={row?.currencyDetailsDB?.currencySymbol !== 'BDX'}
                                // disabled={!isWithdraw}
                                onClick={() => navigate("/withdraw/" + row?.currencyDetailsDB?.curnType.toLowerCase() + "/" + row?.currencyDetailsDB?._id +"?type=USDT")}
                            >
                                Withdraw
                            </Button>
                        </Box>
                        <Box className='text-center'>
                            <Button
                                sx={{
                                    bgcolor: '#C8C8C8',
                                    padding: { xs: '2px 5px', lg: '2px 7px' },
                                    borderRadius: '20px',
                                    color: '#000',
                                    fontSize: { xs: '10px', lg: '11px' },
                                    textAlign: 'center',
                                    // mt: 3,
                                    cursor: 'pointer',
                                    lineHeight: 1.8,
                                    '&:disabled': {
                                        cursor: 'not-allowed',
                                        bgcolor: '#c8c8c8aa',
                                        color: '#000'
                                    },
                                    '&:hover': {
                                        color: 'white'
                                    },
                                    width: '100%'
                                }}
                                disabled
                            // disabled={!isDeposit}
                            // onClick={() => navigate("/deposit/" + 'curnType.toLowerCase()' + "/")}
                            >
                                Deposit
                            </Button>
                        </Box>

                    </Box>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: 20, paddingLeft: 0, paddingRight: 1, }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 0, ml: '-5px' }} className='d-flex justify-content-end align-items-center w-100 my-3'>

                            <Box
                                className='row align-items-center gx-2'
                                sx={{ width: window.innerWidth > 600 ? '20%' : window.innerWidth > 1700 ? '20%' : '30%' }}
                            >
                                <Box className='col-6 text-center'>
                                    <Button
                                        sx={{
                                            bgcolor: '#C8C8C8',
                                            padding: '2px 5px',
                                            borderRadius: '20px',
                                            color: '#000',
                                            fontSize: '10px',
                                            textAlign: 'center',
                                            // mt: 3,
                                            cursor: 'pointer',
                                            lineHeight: 1.8,
                                            '&:disabled': {
                                                cursor: 'not-allowed',
                                                bgcolor: '#c8c8c8aa',
                                                color: '#000'
                                            },
                                            '&:hover': {
                                                color: 'white'
                                            },
                                            width: '100%'
                                        }}
                                        disabled={row?.currencyDetailsDB?.currencySymbol !== 'BDX'}
                                        // disabled={!isWithdraw}
                                        onClick={() => navigate("/withdraw/" + row?.currencyDetailsDB?.curnType.toLowerCase() + "/" + row?.currencyDetailsDB?._id +"?type=USDT")}
                                    >
                                        Withdraw
                                    </Button>
                                </Box>
                                <Box className='col-6 text-center'>
                                    <Button
                                        sx={{
                                            bgcolor: '#C8C8C8',
                                            padding: '2px 5px',
                                            borderRadius: '20px',
                                            color: '#000',
                                            fontSize: '10px',
                                            textAlign: 'center',
                                            // mt: 3,
                                            cursor: 'pointer',
                                            lineHeight: 1.8,
                                            '&:disabled': {
                                                cursor: 'not-allowed',
                                                bgcolor: '#c8c8c8aa',
                                                color: '#000'
                                            },
                                            '&:hover': {
                                                color: 'white'
                                            },
                                            width: '100%'
                                        }}
                                        disabled
                                    // disabled={!isDeposit}
                                    // onClick={() => navigate("/deposit/" + 'curnType.toLowerCase()' + "/")}
                                    >
                                        Deposit
                                    </Button>
                                </Box>
                            </Box>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
};
export default CustomTableRowUSDT;